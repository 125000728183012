import { stringify } from 'query-string';
import { fetchUtils, DataProvider, HttpError } from 'ra-core';
import { UploadAPIUrl, APIUrl } from "./apiClient";
import { responsePathAsArray } from 'graphql';
import moment from "moment";
/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

const UploadFile = (field: string, data: any) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${UploadAPIUrl}uploadFile`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}
const getBaseUrl = (url: string): string => {
    if (url.indexOf("?") != -1) {
        url = url.substring(0, url.indexOf("?"));
    }
    return url;
}

const convertDateFields = (field: string, data: any) => {
    if (data.hasOwnProperty(field)) {
        if (data[field] != null) {
            //data[field] = moment(data[field]).utcOffset("+05:30").format();
            var localTime = moment(data[field]).format('YYYY-MM-DD'); // store localTime
            data[field] = localTime + "T00:00:00.000Z";
        }    
    }
    return data;
}

const uploadFileForField = async (field: string, data: any) => {
    if (data.hasOwnProperty(field)) {
        if (data[field] != null && data[field].rawFile != null) {
            let response = await UploadFile(field, data[field]);
            if (response.files && response.files.length > 0) {
                data[field] = getBaseUrl(response.files[0].url);
            } else {
                data[field] = "";
            }
        }
    }
    return data;
}

export const VSfetchJson = (url: any, options: fetchUtils.Options = {}) => {
    const requestHeaders = fetchUtils.createHeadersFromOptions(options);

    return fetch(url, { ...options, headers: requestHeaders })
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status == 409) {
                return Promise.reject(
                    new HttpError(
                        (json && json.error && json.error.message) || statusText,
                        status,
                        json
                    )
                );
            }
            if (status == 422) {
                let message = "**VALIDATION FAILED** ";
                if (json.error && json.error.details && json.error.details.length > 0) {
                    json.error.details.forEach((element: any) => {
                        let f = element;
                        let mf = f.path + " " + f.message;
                        message = message + " , " + mf;
                    });
                }
                return Promise.reject(
                    new HttpError(
                        message,
                        status,
                        json
                    )
                );
            }
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError(
                        (json && json.message) || statusText,
                        status,
                        json
                    )
                );
            }
            return Promise.resolve({ status, headers, body, json });
        });
};
export default (apiUrl: String, httpClient = VSfetchJson): DataProvider => ({
    
    getList: (resource, params) => {
        
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const filter = params.filter;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        var keys = Object.keys(filter);
        let where = "";
        keys.forEach(function (key) {
            var item = filter[key];
            var keyar = key.split("~");
            if (keyar.length > 1) {
                if (keyar[1] == "like" || keyar[1] == "ilike") {
                    var fld = keyar[0];
                    var opr = keyar[1];
                    where += `&filter[where][${fld}][${opr}]=%${item}%`;
                }else if (keyar[1] == "gt" || keyar[1] == "lt" || keyar[1] == "neq") {
                    var fld = keyar[0];
                    var opr = keyar[1];
                    where += `&filter[where][${fld}][${opr}]=${item}`;
                }
            } else {
                where += `&filter[where][${key}]=${item}`;
            }

        });
        const loopbackquery = `filter[offset]=${(page - 1) * perPage}&filter[limit]=${perPage}&filter[order]=${field} ${order}${where}`;
        const url = `${apiUrl}/${resource}?${loopbackquery}`;

        //const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            let count: string = "10";
            count = headers.get('x-total-count')!.split('/').pop()!
            return {
                data: json,
                total: parseInt(count, 10),
            };
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {

        /* const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`; */

        var ids = params.ids;
        let where = "";
        if (ids.length == 1) {
            where = `filter[where][id]=` + ids[0];
        } else {
            ids.forEach(function (id, index) {
                where += `&filter[where][or][${index}][id]=${id}`;
            });
        }
        const url = `${apiUrl}/${resource}?${where}`;

        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const filter = params.filter;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        var keys = Object.keys(filter);
        let where = "";
        keys.forEach(function (key) {
            var item = filter[key];
            var keyar = key.split("~");
            if (keyar.length > 1) {
                if (keyar[1] == "like" || keyar[1] == "ilike") {
                    var fld = keyar[0];
                    var opr = keyar[1];
                    where += `&filter[where][${fld}][${opr}]=%${item}%`;
                }else if (keyar[1] == "gt" || keyar[1] == "lt" || keyar[1] == "neq") {
                    var fld = keyar[0];
                    var opr = keyar[1];
                    where += `&filter[where][${fld}][${opr}]=${item}`;
                }
            } else {
                where += `&filter[where][${key}]=${item}`;
            }

        });
        if (params.target != undefined) {
            let col = params.target;
            let val = params.id;
            where += `&filter[where][${col}]=${val}`;
        }

        const loopbackquery = `filter[offset]=${(page - 1) * perPage}&filter[limit]=${perPage}&filter[order]=${field} ${order}${where}`;
        const url = `${apiUrl}/${resource}?${loopbackquery}`;

        //const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            let count: string = "10";
            count = headers.get('x-total-count')!.split('/').pop()!
            return {
                data: json,
                total: parseInt(
                    count,
                    10
                ),
            };
        });
    },

    update: async (resource, params) => {
        let data = params.data;
        //debugger

        // if (data.hasOwnProperty("photoUrl")) {
        //     if (data.photoUrl != null) {
        //         let response = await UploadFile("photoUrl", data.photoUrl);
        //         if (response.files && response.files.length > 0) {
        //             data.photoUrl = getBaseUrl(response.files[0].url);
        //         }
        //     } else {
        //         data.photoUrl = "";
        //     }

        // }

        data = await uploadFileForField("photoUrl", data);

        
        if (data.hasOwnProperty("adharcardFrontUrl1")) {
            if (data.adharcardFrontUrl1 != null) {
                let response = await UploadFile("adharcardFrontUrl1", data.adharcardFrontUrl1);
                if (response.files && response.files.length > 0) {
                    data.adharcardFrontUrl = getBaseUrl(response.files[0].url);
                }
            } else {
                data.adharcardFrontUrl = "";
            }
            delete data.adharcardFrontUrl1;
        }

        // data = await uploadFileForField("adharcardFrontUrl", data);
        // data = await uploadFileForField("adharcardBackUrl", data);
        //data = await uploadFileForField("pancardFrontUrl", data);
        if (data.hasOwnProperty("pancardFrontUrl1")) {
            if (data.pancardFrontUrl1 != null) {
                let response = await UploadFile("pancardFrontUrl1", data.pancardFrontUrl1);
                if (response.files && response.files.length > 0) {
                    data.pancardFrontUrl = getBaseUrl(response.files[0].url);
                }
            } else {
                data.pancardFrontUrl= "";
            }
            delete data.pancardFrontUrl1;
        }

        data = await uploadFileForField("pancardBackUrl", data); 
        // data = await uploadFileForField("resumeUrl", data);
        if (data.hasOwnProperty("resumeUrl1")) {
            if (data.resumeUrl1 != null) {
                let response = await UploadFile("resumeUrl1", data.resumeUrl1);
                if (response.files && response.files.length > 0) {
                    data.resumeUrl = getBaseUrl(response.files[0].url);
                }
            } else {
                data.resumeUrl = "";
            }
            delete data.resumeUrl1;
        }

        if (data.hasOwnProperty("coverLetterUrl1")) {
            if (data.coverLetterUrl1 != null) {
                let response = await UploadFile("coverLetterUrl1", data.coverLetterUrl1);
                if (response.files && response.files.length > 0) {
                    data.coverLetterUrl = getBaseUrl(response.files[0].url);
                }
            } else {
                data.coverLetterUrl = "";
            }
            delete data.coverLetterUrl1;
        }


        
        //data = await uploadFileForField("coverLetterUrl", data);

        data = convertDateFields("dob", data);
        data = convertDateFields("doj", data);
        data = convertDateFields("dol", data);
        data = convertDateFields("startDate", data);
        data = convertDateFields("endDate", data);
         
        data = convertDateFields("fromDate", data);
        data = convertDateFields("toDate", data);
        data = convertDateFields("date", data);

        data = convertDateFields("effectiveFrom", data);
        data = convertDateFields("effectiveTo", data);

        if (data.hasOwnProperty("isActive") && !(data.isActive == false || data.isActive == true)) {
            data.isActive = true;
        }
        // if (data.hasOwnProperty("empCode")) {
        //     delete data.empCode;
        // }
        // if (data.hasOwnProperty("optionimage")) {
        //     if (data.optionimage != null) {
        //         let response = await UploadFile("optionimage", data.optionimage);
        //         if (response.files && response.files.length > 0) {
        //             data.optionimage = getBaseUrl(response.files[0].url);
        //         }
        //     } else {
        //         data.optionimage = "";
        //     }
        // }
        //localStorage.setItem("userId", user.userId);
        let userid = parseInt(localStorage.getItem("userId")!);

        //data.createdDate = moment();// "2016-12-13T15:55:27.920Z";
        //data.createdBy = userid;
        
        
        if (data.hasOwnProperty("modifyBy") ) {
            data.modifyBy = userid;
        }
        if( data.hasOwnProperty("modifyBy") &&  data.modifyBy===null )
        {
            data.modifyBy=0;
        }
        data.modifyDate = moment(); //"2016-12-13T15:55:27.920Z";
        if(data.createdDate == null){
            data.createdDate = moment();
        }
        var keys = Object.keys(data);
            keys.forEach((item) => {
                if (data[item] == null) {
                    delete data[item];
                }
                });
    
        //debugger
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>  
    
    
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => (
            { data: responses.map(({ json }) => json.id) })),
            
    create: async (resource, params) => {
        let data = params.data;
        // if (data.hasOwnProperty("photoUrl")) {
        //     if (data.photoUrl != null) {
        //         let response = await UploadFile("photoUrl", data.photoUrl);
        //         if (response.files && response.files.length > 0) {
        //             data.photoUrl = getBaseUrl(response.files[0].url);
        //         }
        //     } else {
        //         data.photoUrl = "";
        //     }

        // }

        data = await uploadFileForField("photoUrl", data);
        data = await uploadFileForField("adharcardFrontUrl", data);
        data = await uploadFileForField("adharcardBackUrl", data);
        data = await uploadFileForField("pancardFrontUrl", data);
        data = await uploadFileForField("pancardBackUrl", data);

        //data = await uploadFileForField("resumeUrl", data);

         if (data.hasOwnProperty("resumeUrl1")) {
            if (data.resumeUrl1 != null) {
                let response = await UploadFile("resumeUrl1", data.resumeUrl1);
                if (response.files && response.files.length > 0) {
                    data.resumeUrl = getBaseUrl(response.files[0].url);
                }
            } else {
                data.resumeUrl = "";
            }

            

        }
        
        data = await uploadFileForField("coverLetterUrl", data);
        
        data = convertDateFields("dob", data);
        data = convertDateFields("doj", data);
        data = convertDateFields("dol", data);
        data = convertDateFields("startDate", data);
        data = convertDateFields("endDate", data);
         
        data = convertDateFields("fromDate", data);
        data = convertDateFields("toDate", data);
        data = convertDateFields("date", data);

        data = convertDateFields("effectiveFrom", data);
        data = convertDateFields("effectiveTo", data);

        // if (data.hasOwnProperty("logourl")) {
        //     if (data.logourl != null) {
        //         let response = await UploadFile("logourl", data.logourl);
        //         if (response.files && response.files.length > 0) {
        //             data.logourl = getBaseUrl(response.files[0].url);
        //         }
        //     } else {
        //         data.logourl = "";
        //     }

        // }
        if (data.hasOwnProperty("isActive") && data.isActive == "") {
            data.isActive = true;
        }
        // if (data.hasOwnProperty("optionimage")) {
        //     if (data.optionimage != null) {
        //         let response = await UploadFile("optionimage", data.optionimage);
        //         if (response.files && response.files.length > 0) {
        //             data.optionimage = getBaseUrl(response.files[0].url);
        //         }
        //     } else {
        //         data.optionimage = "";
        //     }
        // }
        let userid = parseInt(localStorage.getItem("userId")!);
        data.createdDate = moment();// "2016-12-13T15:55:27.920Z";
        data.createdBy = userid;
        //data.modifyBy = userid;
        //data.modifyDate = moment(); //"2016-12-13T15:55:27.920Z";
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
    }
    ,

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});