import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import { Button } from '@material-ui/core';

export const Subjects = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    return (<div style={{ width: '100%',display:'flex',flexDirection:'row' }} >
        <div style={{ width: '49%',marginRight:'1%' }}>
        <ReferenceInput validate={required()} variant="outlined" label="Subject" source="subjectId" reference="subjects" fullWidth={true} formClassName={classes.one_three_input}
        disabled
        perPage={25} sort={{ field: 'name', order: 'ASC' }}
            onChange={(v) => {
                console.log(v);

                form.change('subjectPaymentId', null);
            }}
        >
            <SelectInput
                optionText={choice =>
                    `${choice.name} `
                }
            />
        </ReferenceInput>
        </div>
        <div style={{ width: '49%' }}>
        {!formData.subjectId && <ReferenceInput variant="outlined" perPage={500} sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]}
            label="Subject Payment " source="subjectPaymentId" reference="subject-payments" 
            disabled
             fullWidth={true} formClassName={classes.last_three_input}>
            <SelectInput
                 optionText={choice =>
                    `${choice.name} (${choice.rate} )`
                }
            />
        </ReferenceInput>}    
        {formData.subjectId && <ReferenceInput variant="outlined" perPage={500} sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]}
            label="Subject Payment " source="subjectPaymentId" reference="subject-payments" 
            disabled
             fullWidth={true} formClassName={classes.last_three_input}>
            <SelectInput
                optionText={choice =>
                    `${choice.name} ( ${choice.rate} )`
                }
            />
        </ReferenceInput>}
        </div>
    </div>
    )
}
