import React from 'react';
import { Button, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom";

export const useStyles = makeStyles(theme => ({
    cancel_button: {
        marginLeft: 20,
        marginRight: 20,
    },
    main: {
        display: "flex", width: "100%",
        justifyContent: "space-between",
    },
    btnparent: {
        flex: 1,
    },
}));

export const CreateToolbar = props => {
   // debugger
    const classes = useStyles({});
    const history = useHistory();
    
    const Savebutton = props =>{
        //debugger
        let propsObj = { ...props };
        var employeeCustom =localStorage.getItem("employeeCustom");
        
        if(propsObj.basePath==="/employees" && props.permissions!=undefined && props.permissions!=null && props.permissions.length>0 )
        {
            let myPermissions= props.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() === employeeCustom);
            if (myPermissions.length > 0) {
                let p = myPermissions[0];
                propsObj.hasList = (p.View == 1) ? true : false;
                propsObj.hasShow = (p.View == 1) ? true : false;
                propsObj.hasEdit = (p.Edit == 1) ? true : false;
                propsObj.hasDelete = (p.Delete == 1) ? true : false;
                propsObj.hasCreate = (p.Create == 1) ? true : false;
                propsObj.hasExport = (p.Export == 1) ? true : false;
              }
            // if( propsObj.hasEdit ===false  && props.basePath==="/employees")
            // {
            //     return  null;
            // }

            {
                return  <SaveButton  {...props} onSave={props.onSave}/>;
            }  
        }
        else
            {
                return  <SaveButton  {...props} onSave={props.onSave}/>;
            }  
    }
    
    const cancelForm = () => {
        if (props.onClose) {
            props.onClose();
        }
        else {
            history.goBack();
        }
    }
    return (
        <Toolbar {...props} >
            <div className={classes.main}>
                <div className={classes.btnparent}>
                    <Savebutton {...props}/>
                    <Button
                        className={classes.cancel_button}
                        variant="contained" color="primary"
                        label="Back" size="medium"
                        startIcon={<CancelIcon />}
                        onClick={cancelForm}
                    />
                </div>
                {props.showdelete && <DeleteButton  {...props} />}
            </div>
        </Toolbar>
    )
};