import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";


export const CandidateSubjectExpertCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true,
        date: new Date()
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let date = (formdata.date) ? moment(formdata.date).format() : null;

        let data = {
            candidateId: parseInt(props.employeeId),
            date: date,
            subjectId: formdata.subjectId,
            proficiencyId: formdata.proficiencyId,
            remarks: formdata.remarks? formdata.remarks:"",
            isActive: formdata.isActive
        }

        apiClient.createCandidateSubjectExpert(data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <DateInput source="date" disabled fullWidth={true} label="Entry Date" formClassName={classes.first_inline_input} />
                <ReferenceInput label="Subject" validate={required()} formClassName={classes.last_inline_input}
                    source="subjectId" reference="subjects"  perPage={5000}
                    fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Proficiency" fullWidth={true}
                    perPage={5000} source="proficiencyId" 
                    reference="statuses" 
                    filter={{ statusType: 'PL'}}
                    
                    sort={{ field: 'status', order: 'ASC' }} 

                >
                    <SelectInput  optionText="status" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                

                <TextInput fullWidth={true}  multiline source="remarks" label="Remarks" initialValue={""} />
                <BooleanInput source="isActive" label="Is Active" />
            </SimpleForm>
        </Edit>
    );
}
