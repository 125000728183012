import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

export const CandidateWarningCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true,
        date : new Date()
    }
    const handleSubmit = (formdata) => {
        // console.log(formdata);
        let date = (formdata.date) ? moment(formdata.date).format() : null;
        let dstartdate = (formdata.startDate) ? moment(formdata.startDate).format() : null;
        let denddate = (formdata.endDate) ? moment(formdata.endDate).format() : null;

        let data = {
            candidateId: parseInt(props.employeeId),
            date: date,
            remarks: formdata.remarks,
            isActive: formdata.isActive,
            issuedBy: formdata.issuedBy,
            approvedBy: formdata.approvedBy,
            statusId: formdata.statusId,
            typeId: formdata.typeId,
            startDate: dstartdate,
            endDate: denddate,

        }

        apiClient.createCandidateWarning(data).then(res => {
            // console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <ReferenceInput label="Type" source="typeId" reference="statuses" filter={{ statusGroup: 'ET' }}
                    fullWidth={true} formClassName={classes.first_inline_input} sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="status" />
                </ReferenceInput>
                <DateInput validate={required()}  source="date" fullWidth={true} label="Issue Date"  formClassName={classes.last_inline_input}/>
                <DateInput  source="startDate" fullWidth={true} label="Start Date"  formClassName={classes.first_inline_input}/>
                <DateInput  source="endDate" fullWidth={true} label="End Date"  formClassName={classes.last_inline_input}/>
                <TextInput validate={required()}  fullWidth={true} multiline source="remarks" label="Remarks" />
                <ReferenceInput  validate={required()} label="Issued By"  formClassName={classes.first_inline_input}
                    source="issuedBy" reference="designations" sort={{ field: 'name', order: 'ASC' }} 
                    fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="approvedBy" label="Approved By" fullWidth={true}  formClassName={classes.last_inline_input}/>
                <ReferenceInput label="Status" source="statusId" reference="statuses" filter={{ statusGroup: 'ES' }}
                    fullWidth={true} formClassName={classes.first_inline_input} sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="status" />
                </ReferenceInput>
                <BooleanInput source="isActive" label="Is Active" />
            </SimpleForm>
        </Edit>
    );
}
