import React from 'react';
import { required ,
    Create, SimpleForm, TextInput, DateInput,
    BooleanInput, NumberInput, ImageInput, ImageField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { CreateToolbar } from "../../CreateToolbar";

export const WorkLocationCreate = props => (
    <Create {...props} title=": add new ">
        <SimpleForm redirect="list" title="Create Work Type" toolbar={<CreateToolbar {...props} />} variant="outlined">
                <TextInput  validate={required()} source="name" label="Work Type" fullWidth={true} />
                <TextInput  validate={required()} source="description" label="Description" fullWidth={true} />
            </SimpleForm>
    </Create>
);