import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, FormDataConsumer,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import { CandidatePromotionFields } from "./CandidatePromotionFields";
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";


export const CandidatePromotionCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true,
        fromDate: new Date(),
        fromDepartMentId: 0,
        toDepartMentId: 0,
        fromRoleId: 0,
        toRoleId:0,
        issuedBy:0

    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let fromDate = (formdata.fromDate) ? moment(formdata.fromDate).format() : null;
        let toDate = (formdata.toDate) ? moment(formdata.toDate).format() : null;
        let data = {
            candidateId: parseInt(props.employeeId),
            fromRoleId: formdata.fromRoleId,
            toRoleId: formdata.toRoleId,
            fromDepartMentId: formdata.fromDepartMentId,
            toDepartMentId: formdata.toDepartMentId,
            fromDesignationId: formdata.fromDesignationId,
            toDesignationId: formdata.toDesignationId,
            fromDate: fromDate,
            toDate: toDate,
            remarks: formdata.remarks,
            issuedBy: formdata.issuedBy,
            approvedBy: formdata.approvedBy,
            isActive: formdata.isActive
        }

        apiClient.createCandidatePromotion(data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar label="Save" onClose={props.onCancel} />}
                variant="outlined">

                <FormDataConsumer>
                    {formDataProps => (
                        <CandidatePromotionFields {...formDataProps} />
                    )}
                </FormDataConsumer>

                <ReferenceInput validate={required()} label="Designation From"   formClassName={classes.first_inline_input}
                    source="fromDesignationId" reference="designations"
                    fullWidth={true}sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="To Designation"   formClassName={classes.last_inline_input}
                    source="toDesignationId" reference="designations" sort={{ field: 'name', order: 'ASC' }} 
                    fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>


                <DateInput validate={required()} source="fromDate" fullWidth={true} label="From Date" formClassName={classes.first_inline_input} />
                <DateInput source="toDate" fullWidth={true} label="To Date" formClassName={classes.last_inline_input} />
                <TextInput fullWidth={true} multiline source="remarks" label="Remarks" />
                <ReferenceInput  validate={required()} label="Issued By"   formClassName={classes.first_inline_input}
                    source="issuedBy" reference="departments"
                    fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="approvedBy" label="Approved By" fullWidth={true} formClassName={classes.last_inline_input}/>
                <BooleanInput source="isActive" label="Is Active" />
            </SimpleForm>
        </Edit>
    );
}
