import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput,ReferenceInput,SelectInput,
    DateInput, BooleanInput, NumberInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { createRequire } from 'module';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const StateCreate = props => (
    <Create {...props} title="States" redirect={false} successMessage="State Updated" undoable={false}>
        <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />}
        variant="outlined">
        <ReferenceInput label="Country"  validate={required()} source="countryId" reference="countries" >
            <SelectInput optionText="countryName" />
        </ReferenceInput>
        <TextInput source="stateName" label="State Name:" validate={required()} />
        </SimpleForm>
    </Create>
    );
