import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

export const CandidateSalaryCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let effectiveFrom = (formdata.effectiveFrom) ? moment(formdata.effectiveFrom).format() : null;
        let effectiveTo = (formdata.effectiveTo) ? moment(formdata.effectiveTo).format() : null;
        let data = {
            candidateId: parseInt(props.employeeId),
            salaryHeadId: formdata.salaryHeadId,
            calFormulae: "",
            grossSalary: formdata.grossSalary,
            monthlySalary: formdata.monthlySalary,
            effectiveFrom: effectiveFrom,
            effectiveTo: effectiveTo,
            remarks: formdata.remarks? formdata.remarks:"",
            isActive: formdata.isActive
        }

        apiClient.createCandidateSalary(data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <ReferenceInput label="Salary Head" validate={required()} source="salaryHeadId" reference="salary-heads"
                    fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                {/* <TextInput validate={required()} fullWidth={true} source="calFormulae" label="Calculation Formulae" /> */}
                <NumberInput validate={required()} fullWidth={true} source="grossSalary" label="Gross Salary" formClassName={classes.first_inline_input} />
                <NumberInput validate={required()} fullWidth={true} source="monthlySalary" label="Monthly Salary" formClassName={classes.last_inline_input} />

                <DateInput  validate={required()} source="effectiveFrom" fullWidth={true} label="Effective From" formClassName={classes.first_inline_input} />
                <DateInput source="effectiveTo" fullWidth={true} label="Effective To" formClassName={classes.last_inline_input} />


                <TextInput fullWidth={true}  multiline source="remarks" label="Remarks" />
                <BooleanInput source="isActive" label="Is Active" />
            </SimpleForm>
        </Edit>
    );
}
