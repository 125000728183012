import React, { useEffect, useCallback, useState } from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    useRefresh,
    required,
    useNotify,
    useRedirect,
    minLength,
    maxLength,
    minValue,
    ReferenceField,
    TextField,
    DateField,
    NumberField,
    ReferenceManyField,
    Datagrid,
    FormTab,
    AutocompleteInput,
    Edit, TabbedForm, TextInput, SelectInput,FunctionField,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, useQueryWithStore, FormDataConsumer,BooleanField
} from 'react-admin';
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { EmployeeList } from './EmployeeList'
import Button from '@material-ui/core/Button';
import { Drawer } from '@material-ui/core';
import { EmployeeFields } from './EmployeeFields';
import { DisplayDate } from '../../common/DisplayDate';
import StageCreate from './StageCreate';
import EditIcon from '@material-ui/icons/Edit';
import ViewAgenda from '@material-ui/icons/ViewAgenda';
import StageEdit from './StageEdit';
import CandidateCreate from './CandidateCreate';
import { Subjects } from './Subjects';
import CandidateActionCreate from './CandidateActionCreate';
import CandidateActionView from './CandidateActionView';
import { ContactInfo } from './ContactInfo';



const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 30,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 1200,
    },
}));

export const EvaluatorNotificationEdit = props => {
    const classes = useStyles({});
    const myclasses = myStyles({});
    const refresh = useRefresh();
    const [isAddResourceOpen, setIsAddResourceOpen] = useState(false);
    const [isAddStage, setIsAddStage] = useState(false);
    const [isEditCandidate, setIsEditCandidate] = useState(false);
    const [IsEditRecord, setIsEditRecord] = useState(null);
    const [cols, setCols] = useState([]);
    const [griddata, setData] = useState([]);
    const [isAddCandidate, setIsAddCandidate] = useState(false);
    const [isViewCandidate, setIsViewCandidate] = useState(false);
    const [dataProps, setDataProps] = useState(props);
    let evaluatorId = 0;
    if (localStorage.evaluatorId) {
        evaluatorId = parseInt(localStorage.evaluatorId)
    }
    const RoleTypeId = localStorage.getItem("RoleTypeId"); 
    const isAdmin = (RoleTypeId?.toLocaleLowerCase() == "1" ) ? true : false;

    const onCancel = () => {
        setIsAddResourceOpen(false);
        setIsAddStage(false);
        setIsEditCandidate(false);
        setIsAddCandidate(false);
        setIsViewCandidate(false);
        refresh();

    }
    const onCloseForm = () => {
        setIsAddResourceOpen(false);
        setIsAddStage(false);
        setIsEditCandidate(false);
        setIsAddCandidate(false);
        setIsViewCandidate(false);
        refresh();
        //loadNotificationDetail();
    }
    useEffect(() => {
         
    }, []);
    const loadNotificationDetail = async () => {

        let formData = {
            notificationId: props.id,
        }

        apiClient.getNotificationdetail(formData).then((res) => {
            setData(res);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                let data = [];
                for (let index = 0; index < keys.length; index++) {
                    if (keys[index] != "NotificationDetID") {
                        data.push(keys[index])
                    }
                }
                setCols(data);
            }

        }
        )
    }
    const CustomEdit = (props) => {
        let { record } = props;
        return <Button
            color="secondary" disableElevation
            size="medium"

            onClick={() => {
                props.onClick(record);
            }}
            startIcon={<EditIcon />}>{props.title}</Button>
    }
    const CustomView = (props) => {
        let { record } = props;
        return <Button
            color="secondary" disableElevation
            size="medium"

            onClick={() => {
                props.onClick(record);
            }}
            startIcon={<ViewAgenda />}>{props.title}</Button>
    }

    const CustomCandidate = (props) => {
        const { record } = props;
        const { data, loading, error } = useQueryWithStore({
            type: 'getOne',
            resource: 'candidates',
            payload: { id: record.candidateId },
        });

        if (loading) {
            return <div>Loading....</div>;
        }

        if (error) {
            console.error("Error fetching data:", error);
            return <div>Error loading data</div>;
        }

        if (record.typeId === 46) {
            return <div>{data.firstName} {data.lastName}</div>; // Replace 'firstName' with the actual property you want to display
        } else if (record.typeId === 45) {
            return <div>{data.firstName} {data.lastName}</div>; // Replace 'firstName' with the actual property you want to display
        } else {
            return <div>No data available</div>;
        }
    }


    return (
        <React.Fragment>
            <Edit {...props}   title="Requisition Evaluation" redirect={false} successMessage="Requisition Updated" undoable={false}>
                <TabbedForm redirect="list" variant="outlined"toolbar={<CreateToolbar {...props} mode={"Edit"} />} >
                    <FormTab value="DETAILS" label="Details" >

                        <TextField source="requisitionNo" label="Requisition No" fullWidth={true} disabled />
                        <DateInput source="date" label="Date" fullWidth={true} formClassName={classes.one_three_input} disabled />
                        <ReferenceInput validate={required()} label="For Project" source="projectId" reference="work-role-projects" fullWidth={true} formClassName={classes.two_three_input} disabled>
                            <SelectInput optionText="name" disabled />
                        </ReferenceInput>
                        <TextInput source="notiSubject" label="Requisition Subject" fullWidth={true} formClassName={classes.last_three_input} validate={required()} disabled />
                        <DateInput source="startDate" label="Project Start Date" fullWidth={true} formClassName={classes.one_three_input} validate={required()} disabled />
                        <DateInput source="endDate" label="Project End Date" fullWidth={true} formClassName={classes.two_three_input} validate={required()} disabled />
                        <DateInput source="lastRespDate" label="Last Receiving Date" fullWidth={true} formClassName={classes.last_three_input} validate={required()} disabled />
                        
                        <ReferenceInput validate={required()} label="Business Unit" source="workRoleId" reference="work-roles" fullWidth={true} formClassName={classes.one_three_input} disabled>
                            <SelectInput
                                optionText={choice =>
                                    `${choice.name} `
                                }
                            />
                        </ReferenceInput> 
                        <ReferenceInput validate={required()} label="Technical Skill" source="keySkillId" reference="key-skills" fullWidth={true} formClassName={classes.two_three_input} disabled>
                            <SelectInput
                                optionText={choice =>
                                    `${choice.name} `
                                }
                            />
                        </ReferenceInput>  
                        <ReferenceInput validate={required()} label="Experience" source="experienceId" reference="experiences" fullWidth={true} formClassName={classes.last_three_input} disabled>
                            <SelectInput
                                optionText={choice =>
                                    `${choice.name} `
                                }
                            />
                        </ReferenceInput> 
                        <TextInput source="profile" label="Profile" fullWidth={true}  disabled/> 
                        <RichTextInput source="notiDetails"   label="Requisition Detail" disabled={true} /> 
                        <NumberInput source="totalResource" label="No Of Resources" fullWidth={true} formClassName={classes.one_three_input} validate={required()} disabled />
                        {/* <ReferenceInput perPage={25} sort={{ field: 'firstName', order: 'ASC' }} filterToQuery={searchText => ({ 'firstName~like': searchText })} validate={[required()]} label="Team Lead" source="teamLeadId" reference="employees" fullWidth={true} formClassName={classes.two_three_input} disabled>
                            <SelectInput
                                optionText={choice =>
                                    `${choice.firstName} ${choice.lastName} (${choice.empCode}) `
                                }
                            />
                        </ReferenceInput>  */}
                        <ReferenceInput perPage={25}  filter={{ typeId: 1}} sort={{ field: 'firstName', order: 'ASC' }} filterToQuery={searchText => ({ 'firstName~like': searchText })} validate={[required()]} label="HR Lead" source="teamLeadId" reference="users" fullWidth={true} formClassName={classes.two_three_input}> 
              <AutocompleteInput
                    optionText={choice =>
                        `${choice?.firstName} ${choice?.lastName} `
                    }
                />
            </ReferenceInput>
                        <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]} label="Work Shift" source="shiftId" reference="shifts" fullWidth={true} formClassName={classes.last_three_input} disabled>
                            <SelectInput
                                optionText={choice =>
                                    `${choice.name}`
                                }
                            />
                        </ReferenceInput> 
                        <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]} label="Work Location" source="workLocationId" reference="work-locations" fullWidth={true} formClassName={classes.one_three_input} disabled>
                            <SelectInput
                                optionText={choice =>
                                    `${choice.name}`
                                }
                            />
                        </ReferenceInput>

                        <NumberInput source="securePer" label="Secure %" fullWidth={true} formClassName={classes.two_three_input} validate={required()} disabled /> 
                        <BooleanInput source="isActive" label="Is Active" fullWidth={true} formClassName={classes.last_three_input} validate={required()} disabled />
                        <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <ContactInfo {...formDataProps} />
              )}
            </FormDataConsumer>
                    </FormTab>
                    {/* <FormTab value="Other" label="Subject and Rate setup">  
                        <ReferenceManyField fullWidth={true} addLabel={false} sort={{ field: 'id', order: 'ASC' }} reference="notification-subjects" target="notificationId"> 
                            <Datagrid >   
                            <ReferenceField source="subjectId" reference="subjects" label="Subject" link={false}>
                                    <TextField source="name" />
                                </ReferenceField> 

                            <ReferenceField source="subjectPaymentId" reference="subject-payments" label="Payment Code" link={false}>
                                    <TextField source="name" />
                                </ReferenceField> 
                                <ReferenceField source="subjectPaymentId" reference="subject-payments" label="Min Rate" link={false}>
                                    <NumberField source="minRate" />
                                </ReferenceField>
                                <ReferenceField source="subjectPaymentId" reference="subject-payments" label="Max Rate" link={false}>
                                    <NumberField source="maxRate" />
                                </ReferenceField> 
                                

                                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField> 
                                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>
                                <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab> */}
                      
                    <FormTab value="Other" label="Requisition Candidates">
                         
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            filter={ !isAdmin?{ evaluatorId: evaluatorId }:-1}
                            reference="notification-dets"
                            target="notificationId">
                            <Datagrid >
                                
                               
                                <ReferenceField source="typeId" reference="statuses" label="Candidate Type" link={false}>
                                    <TextField source="status" />
                                </ReferenceField>
                                <CustomCandidate source="candidateId" label="Candidate" />
                                <ReferenceField source="currentStageId" reference="selection-stages" label="Current Stage" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField source="statusId" reference="statuses" label="Status" link={false}>
                                    <TextField source="status" />
                                </ReferenceField>
                                <ReferenceField source="candidateCreatedBy" reference="users" label="Recruiter" link={false}>
                                <FunctionField render={record => `${record.firstName} ${record.middleName} ${record.lastName}`} />
                                </ReferenceField>
                                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                    <TextField source="firstName" />
                                </ReferenceField>

                                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                    <TextField source="firstName" />
                                </ReferenceField>
                                <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />
                                <CustomEdit  title="Action" onClick={(record) => {
                                    setIsEditRecord(record); setIsEditCandidate(true);
                                }}  /> 

                                <CustomView  title="View" onClick={(record) => {
                                    
                                    setIsEditRecord(record); setIsViewCandidate(true);
                                }}  /> 
                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>

                    {/* <FormTab value="Other" label="Recruitment">
                        <Button variant="contained" onClick={() => setIsAddCandidate(true)}
                            color="secondary">
                            Add Candidate

                        </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="notification-det-actions"
                            target="notificationId">
                            <Datagrid >
                                <VSDeleteButton {...props} />
                                <ReferenceField source="typeId" reference="statuses" label="Candidate Type" link={false}>
                                    <TextField source="status" />
                                </ReferenceField>
                                <CustomCandidate source="candidateId" label="Candidate" />
                                <ReferenceField source="statusId" reference="statuses" label="Status" link={false}>
                                    <TextField source="status" />
                                </ReferenceField>
                                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                    <TextField source="firstName" />
                                </ReferenceField>

                                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                    <TextField source="firstName" />
                                </ReferenceField>
                                <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />
                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab> */}

                </TabbedForm>

            </Edit>
            <Drawer anchor="right" onClose={onCancel} classes={{
                paper: myclasses.drawerPaper,
            }}
                open={isAddResourceOpen || isAddStage || isEditCandidate || isAddCandidate || isViewCandidate}>
                {isAddCandidate &&
                    <EmployeeFields 
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        notificationId={props.id}
                        {...props} />}
                {isAddStage &&
                    <StageCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        notificationId={props.id}
                        {...props} />}
                {/* {isEditStage && <StageEdit
                    onClose={onCloseForm}
                    onCancel={onCancel}
                    record={IsEditRecord}
                    {...props} />} */}

                {isEditCandidate &&
                    <CandidateActionCreate 
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        notificationId={props.id}
                        {...props} />} 
                    {isViewCandidate &&
                    <CandidateActionView 
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        notificationId={props.id}
                        {...props} />} 


            </Drawer>



        </React.Fragment>);
}
