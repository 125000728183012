import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer, useNotify,ReferenceManyField,Datagrid,ReferenceField,TextField 
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import { CandidateAdd } from './CandidateAdd';
import { ActionAdd } from './ActionAdd';
import ActionLogs from './ActionLogs';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LatestCandidates from '../../dashboard/LatestCandidates';
import { Drawer } from '@material-ui/core';
const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 750,
    },
}));

const CandidateActionView = props => {

    const myclasses = myStyles({});
    const classes = useStyles({});
    const notify = useNotify();
    const [actionLogs, setActionLogs] = useState([]);   
    const [value, setValue] = React.useState(0);
    const [candidates, setCandidates] = useState([]);
    const [selectedTest, setSelectedTest] = useState();
    const [isTestSelected, setisTestSelected] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(() => { 
        loadLogs(); 
        searchCandidates();
    }, []);
    const loadLogs = async () => {
        apiClient.getNotificationDetLogs(props.record.id).then((res) => {
            let data = [];
            let dataoption = [];
            setActionLogs(res);
            
        }
        )
    }
    
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const searchCandidates = () => {
    
    setCandidates([]);
    
    apiClient.getTestResult(props.record.candidateId,props.record.id).then(res => {
        setCandidates(res);
         
    }).catch(err => {
        console.log(err);
        //setLoading(false);
    });
}

const onInnerSelectTest = async (item) => {
    let ResponseID = item.ResponseID;
    let Subject = item.Subject;
    let UserID = item.UserID;

    let res = await apiClient.getTestResponseEmailLog(ResponseID,UserID,Subject);
    console.log(res);
    setSelectedTest(res); 
    setisTestSelected(true);
} 
const handleClose = useCallback(() => {
    setisTestSelected(false);
}, []);
    return (
        <>
        <Create {...props} >
            <SimpleForm
                
                toolbar={<FormToolbar   hideSave={true}  onClose={props.onClose} onCancel={props.onCancel} showCancel={true} />}
                variant="outlined">
                    <AppBar position="static" className='tabs-parent' formClassName="tabs-parent">
                    <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="simple tabs example">
                        <Tab label="Action Logs" {...a11yProps(0)} />
                        <Tab label="Test Details" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} variant="outlined" index={0} className="tab-panel-fullwidth" formClassName="tab-panel-fullwidth">
                
                {<ActionLogs actionLogs={actionLogs} />}
                    </TabPanel>
                    <TabPanel value={value} variant="outlined" index={1} className="tab-panel-fullwidth" formClassName="tab-panel-fullwidth">
                
                    {<LatestCandidates candidates={candidates} onSelectTest={(item) => { onInnerSelectTest(item); }} />}
                    </TabPanel>
                    
                    
              
            </SimpleForm>
            
        </Create>
        <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
            paper: myclasses.drawerPaper,
        }}
        open={isTestSelected} >
        {selectedTest && <div className="test-details" dangerouslySetInnerHTML={{ __html: selectedTest[0].Result }}></div>}
    </Drawer>
    </>
    );
}
export default CandidateActionView;