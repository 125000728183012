import React, { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    TabbedForm,
    FormTab, EditButton,
    Edit, SimpleForm, TextInput, TextField,
    ReferenceInput, SelectInput,
    ReferenceManyField, Datagrid, NumberField, RichTextField, BooleanField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
} from 'react-admin';
import { useStyles } from "../../formStyles";
import RichTextInput from 'ra-input-rich-text';
import AddQuestionOptionButton from './AddQuestionOptionButton';
import EditQuestionOptionButton from "./EditQuestionOptionButton";
import { Route, useHistory } from 'react-router-dom';
import { Drawer, useMediaQuery, makeStyles } from '@material-ui/core';
import CustomLogoInputDisplay from "../../CustomImage";
import { CreateToolbar } from "../../CreateToolbar";

const useMyStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 350,
    },
}));
export const QuestionEdit = props => {
    const formClasses = useStyles({});
    const classes = useMyStyles({});
    return (
        <Edit {...props} title="Edit question">
            <TabbedForm redirect={false} toolbar={<CreateToolbar {...props} />} variant="outlined">
                <FormTab label="Question">
                    <ReferenceInput label="Test" disabled validate={required()} source="testId" reference="tests" fullWidth={true} formClassName={formClasses.first_inline_input}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput label="Subject" validate={required()} source="subjectId" reference="subjects" fullWidth={true} formClassName={formClasses.last_inline_input}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput label="Question Type" validate={required()} source="questionTypeId" reference="questiontypes" fullWidth={true} formClassName={formClasses.one_three_input}>
                        <SelectInput optionText="questionTypeName" />
                    </ReferenceInput>
                    <NumberInput source="noOfChoice" validate={required()} label="No of Choices" fullWidth={true} formClassName={formClasses.two_three_input} />
                    <NumberInput source="questionNumber" validate={required()} label="Question Number" fullWidth={true} formClassName={formClasses.last_three_input} />
                    <RichTextInput source="question" label="Question" />
                    <ImageInput source="logoUrl" label="Icon" accept="image/*" className="logourl">
                        <ImageField source="logoUrl" title="Icon" />
                    </ImageInput>
                    <CustomLogoInputDisplay field="logoUrl" />
                    <BooleanInput initialValue={true} validate={required()} source="mustAnswer" label="Must Answer" />
                </FormTab>
                <FormTab label="Question Options">
                    <AddQuestionOptionButton />
                    <ReferenceManyField perPage={100} reference="testquestionoptions" target="testQuestionId" addLabel={false} fullWidth={true}>
                        <Datagrid>
                            <EditQuestionOptionButton />
                            <NumberField source="optionNumber" label="Option Number" />
                            <TextField source="optionTitle" label="Option Title" />
                            <RichTextField source="optionText" label="Option Text" />
                            <ImageField source="optionImage" label="Option Image" />
                            <BooleanField source="isCorrect" label="Is Correct" />
                        </Datagrid>
                    </ReferenceManyField>

                </FormTab>
            </TabbedForm>
        </Edit>
    );
}