import React, { useCallback } from 'react';
import {
    useRefresh,
    Button
} from 'react-admin';
import * as apiClient from "./apiClient";

const VSDeleteButton = props => {
    const refresh = useRefresh();

    const deleteRecord = () => {
        if (window.confirm("Are you sure you want to delete this record?")) {
            apiClient.deleteRecord(props.resource, props.record.id).then(res => {
                refresh();
            }).catch(err => {
                console.log(err);
            });
        }
    }

    return (
        <Button
            color="secondary"
            disableElevation
            size="medium"
            style={{ fontSize: 9, backgroundColor: 'red', color: 'white' }}
            redirect={false}
            onClick={deleteRecord}
            label="Delete"
            title="Delete"
        />
    );
};

export default VSDeleteButton;
