import React from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput,
    BooleanInput, NumberInput
} from 'react-admin';
import { useStyles } from "../../formStyles";
import {CreateToolbar} from "../../CreateToolbar";

export const TestCreate = props => {
    const classes = useStyles({});
    return (
        <Create {...props}>
            <SimpleForm  toolbar={<CreateToolbar {...props}/>} variant="outlined">
                <ReferenceInput link={false} label="Subject"   validate={required()} source="subjectId" reference="subjects" fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="name"   validate={required()} label="Test Name" fullWidth={true} formClassName={classes.last_inline_input}/>
                <TextInput source="title"   validate={required()} lable="Topics" fullWidth={true}  formClassName={classes.first_inline_input}/>
                <ReferenceInput link={false} label="Test Type"   validate={required()} source="testTypeId" reference="testtypes" fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="testTypeName" />
                </ReferenceInput>
                <ImageInput source="logoUrl" label="Icon" accept="image/*" className="logourl">
                    <ImageField source="logoUrl" title="Icon" />
                </ImageInput>
                <NumberInput source="testDuration"   validate={required()} label="Test Duration" fullWidth={true} formClassName={classes.one_three_input}/>
                <NumberInput source="passingPercentage"   validate={required()} label="Passing Percentage" fullWidth={true} formClassName={classes.two_three_input}/>
                <NumberInput source="noOfQuestions"   validate={required()} label="No of questions" fullWidth={true} formClassName={classes.last_three_input}/>
                <BooleanInput initialValue={true} source="isActive" label="Is Active" />
            </SimpleForm>
        </Create>
    );
}