import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

export const CandidateEmploymentCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let dol = (formdata.dol) ? moment(formdata.dol).format() : null;
        let doj = (formdata.doj) ? moment(formdata.doj).format() : null;

        let data = {
            candidateId: parseInt(props.employeeId),
            organization: formdata.organization,
            organizationAddress: "",
            organizationCountryId: formdata.organizationCountryId,
            organizationStateId: 0,
            organizationCityId: 0,
            website: "",
            reportingto: formdata.reportingto,
            subjectId: 0,
            empRoleTypeId: formdata.empRoleTypeId,
            department: formdata.department,
            designation: formdata.designation,
            dol: dol,
            doj: doj,
            remarks: "",
            isActive: true
        }

        apiClient.createCandidateEmployment(data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <TextInput fullWidth={true} source="organization" formClassName={classes.first_inline_input}
                    label="Organization" />
                <TextInput validate={required()} formClassName={classes.last_inline_input}
                    fullWidth={true} source="reportingto" label="Reporting To" />
               

                
                <TextInput validate={required()} formClassName={classes.first_inline_input}
                    fullWidth={true} source="department" label="Department" />
               

               <TextInput validate={required()} formClassName={classes.last_inline_input}
                    fullWidth={true} source="designation" label="Designation" />
               

                

                <DateInput source="doj" fullWidth={true} label="Joining Date" formClassName={classes.first_inline_input} />
                <DateInput source="dol" fullWidth={true} label="Leaving Date" formClassName={classes.last_inline_input} />


                {/* <TextInput validate={required()} fullWidth={true} source="organizationAddress" label="Organization Address" /> */}

                <ReferenceInput label="Organization Country" source="organizationCountryId" reference="countries"
                    fullWidth={true} formClassName={classes.one_three_input}
                    sort={{ field: 'countryName', order: 'ASC' }}
                    perPage={5000}>
                    

                    <SelectInput optionText="countryName" />
                </ReferenceInput>

                
                
            </SimpleForm>
        </Edit>
    );
}
