import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const BloodGroupsCreate = props => (
    <Create {...props} title="Blood Group" redirect={false} successMessage="Blood Group updated" undoable={false}>
            <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />} 
            variant="outlined">
            <TextInput source="name" label="Name"/>
            <TextInput source="description" label="Description"/>
            </SimpleForm>
        </Create>
    );
