
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";
import { withRouter } from 'react-router-dom';
import { Link } from 'ra-ui-materialui';
const styles = {
    button: {
        marginTop: "1em",
    },
};

const EditQuestionOptionButton = withStyles(styles)(({ classes, record }) => (
    <Button
        className={classes.button}
        variant="raised"
        component={Link}
        to={{
            pathname: `/testquestionoptions/${record.id}/show`,
            search: `?question_id=${record.testquestionid}`,
        }}
        label="Edit">
        <EditIcon />
    </Button>
));

export default withRouter(EditQuestionOptionButton);