import React, { useState, useCallback } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../../formStyles";

import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
  Button,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  DateInput,
  BooleanInput,
  NumberInput,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
  SaveButton,
  Toolbar,
  DeleteButton
} from "react-admin";

export const ImportEmployees = (props) => {
  const classes = useStyles({});
  const [result, setResult] = useState<any[]>([]);
  const [header, setHeader] = useState<any[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const initialValues = {
    imageSize: 1,
    isVerified: true,
    isActive: true,
    isDefault: true,
    subEventHeaderId: props.eventid
  };
  const handleSubmit = (formdata) => {
    //console.log(formdata);
    setIsProcessing(true);
    setError("");
    apiClient
      .UploadImportFile(
        "importfile",
        formdata.importfile,
        formdata.type,
        formdata.process
      )
      .then((res) => {
        console.log(res);
        console.log(res.response);
        if (res.result == "Success") {
          var keys = Object.keys(res.response[0]);
          setHeader(keys);
          setResult(res.response);
        } else {
          let message = res.response.originalError.info.message;
          setError(message);
        }
        setIsProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
      });
  };

  return (
    <>
      <div className='csv-sample-download'>
        <div className='download-file-caption'>Sample Import Files :</div>
        <div className='csv-file-link'>
          <a href='https://triviumstorage.blob.core.windows.net/trivhra/Employee-Onroll.csv'>
            Candidate
          </a>
        </div>
        <div className='csv-file-link'>
          <a href='https://triviumstorage.blob.core.windows.net/trivhra/Employee-Ctractual-Freelancer.csv'>
            Freelancer
          </a>
        </div>
      </div>
      <Create
        basePath='importemployee'
        location=''
        resource='importemployee'
        {...props}
      >
        <SimpleForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          toolbar={<CreateToolbar label='START IMPORT' onSave={handleSubmit} />}
          variant='outlined'
        >
          <FileInput
            fullWidth={true}
            source='importfile'
            formClassName={classes.one_three_input}
            label='Employee Data File(CSV format as per sample)'
          >
            <FileField source='importfile' title='Employee Data File' />
          </FileInput>
          <SelectInput
            label='Employee Type'
            source='type'
            formClassName={classes.two_three_input}
            choices={[
              { id: "ON-ROLL", name: "Candidate" },
              { id: "FREELANCER", name: "FreeLancer" },
              
            ]}
          />
          <SelectInput
            label='Import Process'
            source='process'
            formClassName={classes.last_three_input}
            choices={[
              { id: "IMPORT", name: "Import Data" },
              { id: "VALIDATE", name: "Validate Import Data" },
              { id: "VIEWDATA", name: "View Imported Data" }
            ]}
          />
        </SimpleForm>
      </Create>
      {isProcessing && (
        <div className='page-loader-container'>
          <div className='loader'>Loading...</div>
        </div>
      )}
      {<div className='errormessage'>{error}</div>}
      {!isProcessing && (
        <table className='employees'>
          {result && result.length > 0 && (
            <thead>
              <tr>
                {header &&
                  header.length > 0 &&
                  header.map((item, index) => {
                    return (
                      <th key={index} className={item}>
                        {item}
                      </th>
                    );
                  })}
              </tr>
            </thead>
          )}
          <tbody>
            {result &&
              result.length > 0 &&
              result.map((item, index) => {
                return (
                  <tr key={index}>
                    {header &&
                      header.length > 0 &&
                      header.map((headerItem, HeaderIndex) => {
                        if (
                          (headerItem == "UpdStatus" &&
                            item[headerItem] != "Success") ||
                          (headerItem == "InsertStatus" &&
                            item[headerItem] != "No Error")
                        )
                          return (
                            <td
                              className='UpdStatus text-red'
                              key={HeaderIndex}
                            >
                              {item[headerItem]}
                            </td>
                          );
                        else
                          return (
                            <td className={headerItem} key={HeaderIndex}>
                              {item[headerItem]}
                            </td>
                          );
                      })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </>
  );
};
