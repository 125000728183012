import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export const EmployeeList=(props)=> {
    const classes = useStyles();

    return (
        <div style={{overflow:"auto"}} >
        <Table className={classes.table} aria-label="customized table">
            <TableHead>
                <TableRow>
                {props.columns && props.columns.map((item, index) => {
                            return ( 
                                <StyledTableCell>{item}</StyledTableCell>
                            )
                        })}
                    
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.map((row,rowIndex) => (
                    <StyledTableRow key={rowIndex}>
                        {/* <StyledTableCell component="th" scope="row">
                            {row.Name} 
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.Date}</StyledTableCell>
                        <StyledTableCell align="right">{row.Description}</StyledTableCell> */}
                        {props.columns && props.columns.map((col, colIndex) => {
                                    return (
                                        <StyledTableCell key={rowIndex + "-" + colIndex}>
                                            {row[col]}
                                        </StyledTableCell>
                                    )
                                })}
                    </StyledTableRow>
                ))}
                 
            </TableBody>
        </Table>
        </div>
//     <table  className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">
//     <tr>
        
//         {props.columns && props.columns.map((item, index) => {
//             return (
//                 <th key={item}>{item}</th>
//             )
//         })}
//     </tr>

//     {props.data && props.data.map((row, rowIndex) => {
//         return (
//             <tr key={rowIndex}>

                
//                 {props.columns && props.columns.map((col, colIndex) => {
//                     return (
//                         <td key={rowIndex + "-" + colIndex}>
//                             {row[col]}
//                         </td>
//                     )
//                 })}
//             </tr>
//         )
//     })}
// </table>
    );
}
