import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,AutocompleteInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const ModuleEdit = props => (
    <Edit {...props} title="Recruiter" redirect={false} successMessage="Recruiter updated" undoable={false}>
            <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />}   
            variant="outlined">
           <TextInput source="name" label="Name" fullwidth={true}/>
            <TextInput source="description" label="Description"  fullwidth={true}/>
            <ReferenceInput variant="outlined" label={"User"}  fullwidth={true} 
                sort={{ field: 'firstName', order: 'ASC' }}
                filter={{ typeId: 1}}
                validate={required()} source={"userId"} reference="users" perPage={500}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                >
                <AutocompleteInput
                            optionText={choice =>
                                `${choice?.firstName} ${choice?.lastName}  `
                            }
                        />
            </ReferenceInput>
            
            <BooleanInput initialValue={true} source="isActive" label="Is Active"  fullwidth={true} />
            
            </SimpleForm>
        </Edit>
    );
