import React, { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import {
    List, Datagrid, TextField, DateField,
    NumberField, BooleanField, EditButton, ImageField,
    CardActions, CreateButton, ExportButton, RefreshButton, ReferenceField
} from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { WorkLocationCreate } from "./WorkLocationCreate";
import { Drawer, useMediaQuery, makeStyles } from '@material-ui/core';
import { WorkLocationEdit } from './WorkLocationEdit';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 350,
    },
}));

const WorkLocationActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
        <Toolbar>
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </Toolbar>
    );
export const WorkLocationList = props => {
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
    
    const classes = useStyles({});
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/work-locations');
    }, [history]);


    return (
        <div className={classes.root}>
            <Route path="/work-locations/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params 
                    );
                        
                        
                    return (
                        <Fragment>
                            <List title="Work Type"
                                {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={ propsObj.hasDelete}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                actions={<WorkLocationActions />}
                                perPage={25}
                                sort={{ field: 'id', order: 'DESC' }}
                            >
                                <Datagrid rowClick="show">
                                {propsObj.hasEdit ? <EditButton /> :  null}
                                    
                                    <TextField source="name" label="Work Type" />
                                    <TextField source="description" label="Description" />
                                    <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                                    <TextField source="userName" />
                                    </ReferenceField>
                                    <DateField  source="createdDate" label="Created Date"/>
                                    <ReferenceField source="modifyBy" reference="employees" label="Modified By"  link={false}>
                                        <TextField source="firstName" />
                                    </ReferenceField>
                                    <DateField  source="modifyDate" label="Modified Date"/>
                                </Datagrid>
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {isMatch && match.params.id !== 'create' ? (
                                    <WorkLocationEdit
                                        id={match.params.id}
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                ) : null}
                                {isMatch && match.params.id === 'create' ? (
                                    <WorkLocationCreate
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                }}
            </Route>
        </div>
    );
}