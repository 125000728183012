import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    first_inline_input:{
        display:'inline-block',
        width:'48%'
    }, 
    last_inline_input: {
        display:'inline-block',
        marginLeft: '4%',
        width:'48%'
    },
    one_three_input:{
        display:'inline-block',
        width:'30%'
    },
    two_three_input:{
        display:'inline-block',
        marginLeft: '5%',
        width:'30%'
    },
    last_three_input:{
        display:'inline-block',
        marginLeft: '5%',
        width:'30%'
    },
    one_4_input:{
        display:'inline-block',
        width:'23%'
    },
    two_4_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'23%'
    },
    three_4_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'23%'
    },
    last_4_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'23%'
    },
}));