import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, DateInput,ReferenceInput,SelectInput,
    BooleanInput, NumberInput, ImageInput, ImageField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { CreateToolbar } from "../../CreateToolbar";

export const CountryCreate = props => (
    <Create {...props} title="Event" redirect={false} successMessage="Event updated" undoable={false}>
        <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />}
        variant="outlined">
                <TextInput validate={required()} source="countryCode" label="Code:" fullWidth={true} />
                <TextInput validate={required()} source="countryName" label="Name:" fullWidth={true} />
                <TextInput source="regionName" label="Region:" fullWidth={true} />
                <TextInput source="dateTimeFormat" label="Date Format:" fullWidth={true} />
                <TextInput source="timeFormat" label="Time Format:" fullWidth={true} />
                <TextInput source="currencyCode" label="Currency:" fullWidth={true} />
                <TextInput source="currencySymbol" label="Currency Symbol:" fullWidth={true} />
                <TextInput source="dollarExchangeRate" label="Dollar Exchange Rate:" fullWidth={true} />
                <TextInput source="timeZone" label="Time Zone:" fullWidth={true} />
                <TextInput source="language" label="Language:" fullWidth={true} />
        </SimpleForm>
    </Create>
);