import React, { useState, useEffect } from 'react';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';
import subjects from "./screen/subject";
import tests from "./screen/test";
import questions from "./screen/question";
import roles from "./screen/roles";
import users from './screen/user';
import testquestionoptions from "./screen/questionoption";
import VSDataProvider from "./VSDataProvider";
import { APIUrl } from "./apiClient";
import LoginByOTP from "./account/LoginByOTP";
import countries from "./screen/country";
import states from "./screen/state";
import employees from "./screen/freelancer";
import candidate from "./screen/candidate";
import cities from "./screen/city";
import systemsetup from "./screen/systemsetup";
import worklocation from "./screen/worklocation";
import roletypes from './screen/roletypes';
import salaryheads from './screen/salaryheads';
import departments from './screen/departments';
import workroles from './screen/workroles';
import workrolesprojects from './screen/workrolesprojects';
import shifts from './screen/shifts';
import salutations from './screen/salutations';
import weekoffs from './screen/weekoffs';
import designations from './screen/designations';
import holidays from './screen/holidays';
import LeaveCategories from './screen/LeaveCategories';
import leavetypes from './screen/leavetypes';
import recruitsources from './screen/recruitsources';
import bloodgroups from './screen/bloodgroups';
import qualifications from './screen/qualifications';
import subjectexperts from './screen/subjectexperts';
import notifications from './screen/notifications';
import employeeleavelist from './screen/employeeleavelist';
import employeeleaveapprove from './screen/employeeleaveapprove';
import keyskills from './screen/keyskills';
import evaluator from './screen/evaluator';
import experience from './screen/experience';
import recruiters from './screen/recruiters';
import selectionstages from './screen/selectionstages';
import recruiternotifications from './screen/recruiternotifications';
import subjectpayment from './screen/subjectpayment';
import evaluatornotifications from './screen/evaluatornotifications';
import nationalities from './screen/nationalities';
import freelancer from './screen/freelancer';
import candidateuser from './screen/candidateuser';
import freelanceruser from './screen/freelanceruser';


const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {
    const [dataProvider, setDataProvider] = useState({});

    useEffect(() => {
        setDataProvider(VSDataProvider(APIUrl));
    }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="testtypes" list={ListGuesser} edit={EditGuesser} />
            <Resource name="questiontypes" list={ListGuesser} edit={EditGuesser} />
            <Resource name="users" {...users} />
            <Resource title="Country" name="countries" {...countries} />
            <Resource title="State" name="states" {...states} />
            <Resource title="City" name="cities" {...cities} />
            <Resource title="Setup Info" name="system-setups" {...systemsetup} />
            <Resource title="Work Location" name="work-locations" {...worklocation} />
            <Resource title="User Roles" name="role-types" {...roletypes} />
            <Resource name="salary-heads" {...salaryheads} />
            <Resource name="departments" {...departments} />
            <Resource name="designations" {...designations} />
            <Resource name="work-roles" {...workroles} />
            <Resource name="work-role-projects" {...workrolesprojects} />
            <Resource name="shifts" {...shifts} />
            <Resource name="statuses" list={ListGuesser} edit={EditGuesser} />
            <Resource name="salutations" {...salutations} />
            <Resource name="weekoffs" {...weekoffs} />
            <Resource name="holiday-lists" {...holidays} />
            <Resource name="leave-categories"  {...LeaveCategories} />
            <Resource name="leave-types"  {...leavetypes} />
            <Resource name="recruit-sources"  {...recruitsources} />
            <Resource name="blood-groups" {...bloodgroups} />
            <Resource name="educations" {...qualifications} />
            <Resource name="subjects" {...subjectexperts} />
            <Resource name="freelancers" {...freelancer} />
            
            <Resource name="employees"  list={ListGuesser} edit={EditGuesser} />
            <Resource name="candidates" {...candidate} />
            <Resource name="employee-educations" list={ListGuesser} edit={EditGuesser} />
            <Resource name="employee-prev-infos" list={ListGuesser} edit={EditGuesser} />
            <Resource name="employee-leaves" list={ListGuesser} edit={EditGuesser} />
            <Resource name="employee-leavess" list={ListGuesser} edit={EditGuesser} />
            <Resource name="employee-apply-leaves" {...employeeleavelist} />
            <Resource name="employee-leaves-approve"  {...employeeleaveapprove} />
            <Resource name="employee-subject-experts" list={ListGuesser} edit={EditGuesser} />
            <Resource name="employee-salaries" list={ListGuesser} edit={EditGuesser} />
            <Resource name="employee-promotions" list={ListGuesser} edit={EditGuesser} />
            <Resource name="employee-warnings" list={ListGuesser} edit={EditGuesser} />
            <Resource name="employee-reportings" list={ListGuesser} edit={EditGuesser} />
            <Resource name="employee-departments" list={ListGuesser} edit={EditGuesser} />
            <Resource name="notifications" {...notifications} />
            <Resource name="recruiternotifications" {...recruiternotifications} />
            <Resource name="notification-recruters" {...recruiternotifications} />

            <Resource name="evaluatornotifications" {...evaluatornotifications} />
            <Resource name="notification-evaluators" {...evaluatornotifications} />
            
            
            <Resource name="testtypes" list={ListGuesser} edit={EditGuesser}/>
            <Resource name="questiontypes" list={ListGuesser} edit={EditGuesser}/>
            {/* <Resource name="subjects" {...subjects}/> */}
            <Resource name="tests" {...tests}/>
            <Resource name="testquestions" {...questions}/>
            <Resource name="testquestionoptions" {...testquestionoptions}/>
            <Resource name="key-skills" {...keyskills}/>
            <Resource name="evaluators" {...evaluator}/>
            <Resource name="experiences" {...experience}/>
            <Resource name="recruiters" {...recruiters}/>
            <Resource name="selection-stages" {...selectionstages}/>
            <Resource name="notification-stages" list={ListGuesser} edit={EditGuesser}/>
            <Resource name="notification-dets" list={ListGuesser} edit={EditGuesser}/>
            <Resource name="statuses" list={ListGuesser} edit={EditGuesser}/>
            <Resource name="notification-det-actions" list={ListGuesser} edit={EditGuesser}/>
            <Resource name="subject-payments" {...subjectpayment}/>   
            <Resource name="notification-evaluators" list={ListGuesser} edit={EditGuesser}/>   
            {/* <Resource name="notification-recruters" list={ListGuesser} edit={EditGuesser}/>    */}
            <Resource name="notification-subjects" list={ListGuesser} edit={EditGuesser}/>   
            <Resource name="candidate-key-skills" list={ListGuesser} edit={EditGuesser}/>   
            <Resource name="employee-key-skills" list={ListGuesser} edit={EditGuesser}/>   
            <Resource name="candidate-subject-experts" list={ListGuesser} edit={EditGuesser}/>   
            <Resource name="candidate-educations" list={ListGuesser} edit={EditGuesser}/>   
            <Resource name="candidate-prev-infos" list={ListGuesser} edit={EditGuesser}/>   
            <Resource name="candidate-salaries" list={ListGuesser} edit={EditGuesser}/>   
            <Resource name="nationalities" {...nationalities}/>
            <Resource name="candidate-project-mappings" list={ListGuesser} edit={EditGuesser}/>   
            
            <Resource name="candidate-users" {...candidateuser} />
            <Resource name="freelancer-users" {...freelanceruser} />
            
            
            
            

        </Admin>
    );
};

export default App;
