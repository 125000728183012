import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";

export const CandidatePromotionFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    return (
        <div className="employee-promotion-field-parent">
            <ReferenceInput variant="outlined" validate={required()} label="Department From"
                onChange={(v) => {
                    form.change("fromRoleId", 0);
                }}
                sort={{ field: 'name', order: 'ASC' }} 
                 formClassName={classes.first_inline_input}
                source="fromDepartMentId" reference="departments"
                fullWidth={true}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput variant="outlined" validate={required()} label="To Department"
                onChange={(v) => {
                    form.change("toRoleId", 0);
                }}
                sort={{ field: 'name', order: 'ASC' }} 
                 formClassName={classes.last_inline_input}
                source="toDepartMentId" reference="departments"
                fullWidth={true}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            {formData.fromDepartMentId > 0 &&  <ReferenceInput variant="outlined" validate={required()} label="From Role"
                filter={{ departmentId: formData.fromDepartMentId }}
                sort={{ field: 'name', order: 'ASC' }} 
                  formClassName={classes.first_inline_input}
                source="fromRoleId" reference="work-roles"
                fullWidth={true}>
                <SelectInput optionText="name" />
            </ReferenceInput>}
            {formData.toDepartMentId > 0 &&  <ReferenceInput variant="outlined" validate={required()} label="To Role"
                filter={{ departmentId: formData.toDepartMentId }}
                sort={{ field: 'name', order: 'ASC' }} 
                 formClassName={classes.last_inline_input}
                source="toRoleId" reference="work-roles"
                fullWidth={true}>
                <SelectInput optionText="name" />
            </ReferenceInput>}
        </div>
    )
}
