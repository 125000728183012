import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput,ReferenceInput,SelectInput,
    DateInput, BooleanInput, NumberInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const CountryEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props} title="Country" redirect={false} successMessage="Country updated" undoable={false}>
            <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />}
            variant="outlined">
                <TextInput validate={required()} source="countryCode" label="Code:" fullWidth={true} />
                <TextInput validate={required()} source="countryName" label="Name:" fullWidth={true} />
                <TextInput source="regionName" label="Region:" fullWidth={true} />
                <TextInput source="dateTimeFormat" label="Date Format:" fullWidth={true} />
                <TextInput source="timeFormat" label="Time Format:" fullWidth={true} />
                <TextInput source="currencyCode" label="Currency:" fullWidth={true} />
                <TextInput source="currencySymbol" label="Currency Symbol:" fullWidth={true} />
                <TextInput source="dollarExchangeRate" label="Dollar Exchange Rate:" fullWidth={true} />
                <TextInput source="timeZone" label="Time Zone:" fullWidth={true} />
                <TextInput source="language" label="Language:" fullWidth={true} />
            </SimpleForm>
        </Edit>
    );
}