import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import * as apiClient from "../../apiClient";
import {ISubject} from "../../model/ISubject";
import noimagefound from "../../images/noimagefound.png";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
    },
    subjectsContainer: { display: 'flex', flexWrap: "wrap", justifyContent: "center" },
    subject: { width: 150, margin: 20,
        display:"flex", justifyContent:"center", alignItems:"flex-end",
         marginBottom: 20, border: "1px solid #cccccc",backgroundColor:"#eeeeee", },
    subjectImage: {
        maxWidth: "100%",
        width: "100%",
        boxSizing:"border-box",
        padding: 10,
    },
    subjectName: {
        textDecoration: "none", color: "#000", marginBottom: 10,
        fontWeight: "bold", textAlign: "center"
    },
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
});

const StudentSubjectList = () => {
    const [subjects, setSubjects] = useState<ISubject[]>([]);
    const classes = useStyles({});

    const GetAllSubjects = () => {
        apiClient.GetAllSubjects().then((response) => {
            console.log(response);
            setSubjects(response);
        });
    }
    useEffect(() => {
        GetAllSubjects();
    }, []);
    return (
        <div className={classes.root}>
            <h1>Subjects</h1>
            <div className={classes.subjectsContainer}>
                {subjects && subjects.map((item, index) => {
                    return (
                        <div key={index} className={classes.subject}>
                            <Link to={"/studentsubjectdetail/" + item.id}>
                                {item.logourl && <img className={classes.subjectImage} src={item.logourl} />}
                                {!item.logourl && <img className={classes.subjectImage} src={noimagefound} />}
                                <div className={classes.subjectName}>{item.name}</div>
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default StudentSubjectList;
