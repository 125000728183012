import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const SalaryHeadsCreate = props => (
    <Create {...props} title="Salary Heads" redirect={false} successMessage="Salary Head Updated" undoable={false}>
        <SimpleForm redirect="list"
            variant="outlined">
            <TextInput source="name" label="Name" />
            <TextInput source="description" label="Description" />
            <SelectInput validate={required()} source="availableDays" 
                choices={[
                    { id: 'Monthly', name: 'Monthly' },
                    { id: 'Yearly', name: 'Yearly' },
                ]} />
             <SelectInput validate={required()} source="calculationType"  label="Calculation Type"
                choices={[
                    { id: 'Fixed', name: 'Fixed' },
                    { id: 'Formula', name: 'Formula' },
                ]} />   
            <TextInput source="calFormulae" label="Calculation Formulae" />
        </SimpleForm>
    </Create>
);
