import React, { useState, useEffect } from 'react';
import * as apiClient from "../../apiClient";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const EmployeeWarningListReport = (props: any) => {
    // const classes = useStyles();
    const classes = useStyles({});
    const [cols, setCols] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');
    const [types, setTypes] = useState<any>([]);
    const [type, setType] = useState<any>(0);
    const [statuses, setStatuses] = useState<any>([]);
    const [status, setStatus] = useState<any>(0);
    const [wariningtypes, setWariningTypes] = useState([]);
    const [wariningtype, setWariningtype] = useState<any>(0);
    const [warningStatuses, setWarningStatuses] = useState<any>([]);
    const [warningStatus, setWarningStatus] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);

    const getWariningType = () => {
        apiClient.getWarningStatuses().then(res => {
            setWariningTypes(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getWarningStatus = () => {
        apiClient.getWarningTypes().then(res => {
            setWarningStatuses(res);
        }).catch(err => {
            console.log(err);
        });
    }

    // const getDepartments = () => {
    //     apiClient.getDepartments().then(res => {
    //         //console.log(res);
    //         setDepartments(res);
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // }
    // const getDesignations = () => {
    //     apiClient.getResource("designations").then(res => {
    //         setDesignations(res);
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // }
    // const getLeaveCategories = () => {
    //     apiClient.getResource("leave-categories").then(res => {
    //         setLeavecategories(res);
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // }
    // const getShifts = () => {
    //     apiClient.getResource("shifts").then(res => {
    //         setShifts(res);
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // }

    // const getRoleTypes = () => {
    //     apiClient.getRoleTypes().then(res => {
    //         console.log(res);
    //         setRoles(res);
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // }

    const generateReport = () => {
        setFectched(false);
        setIsLoading(true);

        let sDate =  moment(startdate).format('YYYY-MM-DD');
        let eDate =  moment(enddate).format('YYYY-MM-DD');

        let data = {
            StartDate: sDate ,
            EndDate: eDate,
            WarningTypeID: parseInt(wariningtype),
            StatusID: parseInt(warningStatus) 
        };

        apiClient.getWarningEmployeeListReport(data).then(res => {
            console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                setCols(keys);
            }
            setIsLoading(false);

        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        let n = localStorage.getItem('username');
        if(n == null){
            props.history.push("/login");
        }
        getWariningType();
        getWarningStatus();
        // getEmployees();
        // getDepartments();
        // getRoleTypes();
        // getDesignations();
        // getLeaveCategories();
        // getShifts();
    }, []);


    return (
        <div className="employee-list-report">
            <div className="filters">
                <div className="filters-input">
                    <div className="start-date">Start Date : <input type="date" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} /></div>
                    <div className="end-date"> End Date : <input type="date" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} /></div>
                </div>
                <FormControl size="small" className={classes.formControl} variant="outlined">
                        <InputLabel id="demo-simple-select-label">Warning Types</InputLabel>
                        <Select variant="outlined" onChange={(e) => { setWariningtype(e.target.value); }}>
                            {wariningtypes && wariningtypes.map((item: any) => {
                                return (<MenuItem value={item.id}>{item.status}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    <FormControl size="small" className={classes.formControl} variant="outlined">
                        <InputLabel id="demo-simple-select-label">Warning Status</InputLabel>
                        <Select variant="outlined" onChange={(e) => { setWarningStatus(e.target.value); }}>
                            {warningStatuses && warningStatuses.map((item: any) => {
                                return (<MenuItem value={item.id}>{item.status}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                {fectched && data.length > 0 && <div>Total {data.length} employees found</div>}
                {fectched && data.length == 0 && <div>No employees found</div>}
                <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"Employee_list.csv"} data={data}>Download Report</CSVLink>}
                </div>
            </div>
            <div className="filter-action">
                <Button variant="contained"
                    onClick={() => {
                        generateReport();
                    }}
                    startIcon={<AssessmentIcon />}
                    color="primary">
                    Generate Report
                </Button>
            </div>
            {isLoading && <CircularProgress />}
            <div className="report-data">
                <div className="w3-responsive">
                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">
                        <tr>
                            {cols && cols.map((item, index) => {
                                return (
                                    <th key={item}>{item}</th>
                                )
                            })}
                        </tr>

                        {data && data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {cols && cols.map((col, colIndex) => {
                                        return (
                                            <td key={rowIndex + "-" + colIndex}>
                                                {row[col]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        </div>
    );
};