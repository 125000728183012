import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer,useNotify
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const StageCreate = props => {
    const classes = useStyles({});
    const notify = useNotify();
    const [initialValues, setSelectRecord] = useState({
        remarks:""
    }); 

   
    const handleSubmit = (formdata) => {
        console.log(formdata);
        let m = moment(new Date());
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
            userId = parseInt(userId);
        }
        let data = {
            notificationId: parseInt(props.notificationId),
            statusId: parseInt(formdata.statusId),
            selectionStageId: parseInt(formdata.selectionStageId), 
            remarks:formdata.remarks,
            isActive: true,
            createdBy: userId,
            modifyBy: 0,
            createdDate: m.toISOString(),
            modifyDate: m.toISOString(),

        } 
        apiClient.addEntiyRecord("notification-stages", data).then(res => {
            notify("notification-stages Created ") 
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    
  
    return (
        <Create {...props} >
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} showCancel={true} />}
                variant="outlined">
       
       <ReferenceInput label="Selection Stage" fullWidth={true}
                    perPage={5000} source="selectionStageId"
                    
                    reference="selection-stages"
                    formClassName={classes.first_inline_input}
                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <SelectInput  optionText="name" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>

                <ReferenceInput label="Status" fullWidth={true}
                    perPage={5000} source="statusId" 
                    reference="statuses"
                    formClassName={classes.last_inline_input}
                    filter={{ statusType: 'EMPLOYEEROLE'}}
                    sort={{ field: 'status', order: 'ASC' }} 

                >
                    <SelectInput  optionText="status" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>         
               
                <TextInput source="remarks"  label="Remarks" fullWidth={true} multiline />
            </SimpleForm>
        </Create>
    );
}
export default StageCreate;