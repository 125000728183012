import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,FormDataConsumer,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import { AddressFields } from "../../AddressFields";
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

export const CandidateEducationCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let startDate = (formdata.startDate) ? moment(formdata.startDate).format() : null;
        let endDate = (formdata.endDate) ? moment(formdata.endDate).format() : null;
        let data = {
            candidateId: parseInt(props.employeeId),
            educationId: formdata.educationId,
            institution: formdata.institution,
            institutionAddress: "",
            institutionCountryId: formdata.institutionCountryId,
            institutionStateId: 0,
            institutionCityId: 0,
            institutionWebsite: "",
            subjectId: formdata.subjectId,
            startDate: startDate,
            endDate: endDate,
            remarks: "",
            degree: formdata.degree,
            grade: formdata.grade,
            isPursuing:formdata.isPursuing,
            
            isActive:true
        }

        apiClient.createCandidateEducation(data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <ReferenceInput label="Education" validate={required()} source="educationId" reference="educations"
                    fullWidth={true} formClassName={classes.first_inline_input} sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Subject" validate={required()} formClassName={classes.last_inline_input}
                source="subjectId" reference="subjects" sort={{ field: 'name', order: 'ASC' }} 
                    fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput validate={required()} 
                fullWidth={true} source="institution" 
                label="Institution Name" />
                {/* <TextInput fullWidth={true} validate={required()}  source="institutionwebsite" formClassName={classes.last_inline_input}
                label="Institution Website" /> */}
                
                <TextInput   formClassName={classes.first_inline_input}
                fullWidth={true} source="Grade" 
                label="Degree" />
                <TextInput   formClassName={classes.last_inline_input}
                fullWidth={true} source="grade" 
                label="Grade" />
                <DateInput source="startDate"   fullWidth={true} label="Start Date" formClassName={classes.first_inline_input} />
                <DateInput source="endDate"     fullWidth={true} label="End Date" formClassName={classes.last_inline_input}/>
                {/* <TextInput validate={required()} fullWidth={true} source="institutionAddress" label="Institution Address" /> */}
                <BooleanInput initialValue={false} source="isPursuing" label="Is Pursuing" />

                <ReferenceInput variant="outlined" label={"Country"}  source={"institutionCountryId"} reference="countries"
                 
                sort={{ field: 'countryName', order: 'ASC' }}
                fullWidth={true} perPage={5000}>
                <SelectInput optionText="countryName" />
            </ReferenceInput>

            

                {/* <ReferenceInput label="Institution Country"  validate={required()} source="institutionCountryId" reference="countries"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="countryName" />
                </ReferenceInput>

                <ReferenceInput label="Institution State"  validate={required()} source="institutionStateId" reference="states"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="stateName" />
                </ReferenceInput>
                <ReferenceInput label="Institution City" validate={required()}  source="institutionCityId" reference="cities"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="cityName" />
                </ReferenceInput> */}

                {/* <TextInput fullWidth={true} multiline  source="remarks"  label="Remarks"/> */}
            </SimpleForm>
        </Edit>
    );
}
