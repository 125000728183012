import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput,
    BooleanInput, NumberInput
} from 'react-admin';
import { useStyles } from "../../formStyles";
import RichTextInput from 'ra-input-rich-text';
import { parse } from "query-string";
import {CreateToolbar} from "../../CreateToolbar";

export const QuestionCreate = props => {
    const classes = useStyles({});
    const { test_id: test_id_string } = parse(props.location.search);
    const test_id = test_id_string ? parseInt(test_id_string, 10) : "";

    const redirect = test_id ? `/tests/${test_id}/1` : "show";
    return (
        <Create {...props} title="Add new question">
            <SimpleForm variant="outlined"  toolbar={<CreateToolbar {...props}/>}
            defaultValue={{ testId:test_id }}
            redirect={redirect}
            >
            <ReferenceInput label="Test" disabled source="testId" reference="tests"  validate={required()} fullWidth={true} formClassName={classes.first_inline_input} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Subject" source="subjectId" validate={required()} reference="subjects" fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Question Type" source="questionTypeId"  validate={required()} reference="questiontypes" fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="questionTypeName" />
                </ReferenceInput>
                <NumberInput source="noOfChoice" label="No of Choices"  validate={required()} fullWidth={true} formClassName={classes.two_three_input} />
                <NumberInput source="questionNumber" label="Question Number" validate={required()} fullWidth={true} formClassName={classes.last_three_input} />
                <RichTextInput source="question" label="Question"  />              
                <ImageInput source="logoUrl" label="Icon" accept="image/*" className="logourl">
                    <ImageField source="logoUrl" title="Icon" />
                </ImageInput>
                <BooleanInput initialValue={true} source="mustAnswer" validate={required()}  label="Must Answer" />
            </SimpleForm>
        </Create>
    );
}