import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const SystemsetupEdit = props => (
    <Edit {...props} title="System Setup" redirect={false} successMessage="System Setup Updated" undoable={false}>
            <SimpleForm redirect="list"  toolbar={<CreateToolbar {...props} />} variant="outlined">
            <TextInput  validate={required()} fullWidth={true} source="systemSetupCode" label="Code "/>
            <TextInput  validate={required()} fullWidth={true} source="name" label="Name "/>
            <RichTextInput  validate={required()} fullWidth={true}  source="value" label="Value "/>
            <TextInput  fullWidth={true}  multiline={true} source="remarks" label="Remarks "/>
            </SimpleForm>
        </Edit>
    );
