import React from 'react';
import {
    List, Datagrid, TextField, DateField,TextInput,
    ReferenceField, ImageField, Filter, SearchInput, ReferenceInput, AutocompleteInput,
    NumberField, BooleanField, EditButton
} from 'react-admin';


const TestFilter = props => (
    <Filter {...props}  variant="outlined" >
        <TextInput   variant="outlined"  label="Test Name" source="name~like" alwaysOn />
        <ReferenceInput  variant="outlined"  label="Subject" perPage={2500} sort={{ field: 'name', order: 'ASC' }} source="subjectId" reference="subjects" alwaysOn>
            <AutocompleteInput  variant="outlined" 
                optionText={choice => {
                    return (choice?.name) ? choice?.name : "All";
                }
                }
            />
        </ReferenceInput>
    </Filter>
);
export const GetTestLink = (props) => {
    let {record} = props;
    return (
    <span>http://triviumquiz.triviumedu.com/#/login?invite_code={record.id}</span>
    
    )
}

export const TestList = props => (
    <List {...props} filters={<TestFilter />} bulkActionButtons={false}>
        <Datagrid rowClick={false}>
            <EditButton label="Modify"/>
            <GetTestLink label="Test Link"/>
            <ReferenceField link={false} label="Subject" source="subjectId" reference="subjects" >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="title" label="Title" />
            <ReferenceField link={false} label="Test Type" source="testTypeId" reference="testtypes" >
                <TextField source="testTypeName" />
            </ReferenceField>
            <ImageField source="logoUrl" label="Icon" />
            <BooleanField source="isActive" label="Is Active" />
            <NumberField source="testDuration" label="Test Duration" />
            <NumberField source="passingPercentage" label="Passing Percentage" />
            <NumberField source="noOfQuestions" label="No of questions" />
        </Datagrid>
    </List>
);