import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from '@material-ui/core';
import Welcome from './Welcome';
import SubjectCount from './SubjectCount';
import TestCount from './TestCount';
import TestResult from "./TestResult";
import LatestCandidates from './LatestCandidates';
import LatestTest from "./LatestTest";
import CandidateCount from './CandidateCount';
import QuestionCount from './QuestionCount';
import * as apiClient from "../apiClient";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CountBlock from "./CountBlock";
import HolidayList from "./HolidayList";
import DashboardLeavesList from "./DashboardLeavesList";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    useNotify
} from "react-admin";
import { Drawer } from '@material-ui/core';
import { CandidateProgress } from './CandidateProgress';

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 1000,
    },
}));

export interface IHeader {
    SubjectCount: number;
    TestCount: number;
    QuestionCount: number;
    CandidateCount: number;
}
export interface ICandidate {
    Candidate_Name: string;
    Email: string;
    MobileNumber: string;
    Joined_On: string;
}
export interface ILatestTest {
    Subject: string;
    Test: string;
    Question: string;
    OptionText: string;
    isRightOption: boolean;
}
export interface ITestResult {
    UserName: string;
    TestDate: string;
    Subject: string;
    TestName: string;
    SecurePercentage: number;
    Result: string;
}
const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center", flexWrap: "wrap" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
});

const Dashboard = () => {
    const [role, setRole] = useState("Employee");
    const [roletypeid, setRoletypeid] = useState<any>(0);
    
    const [uid, setUid] = useState(0);
    const [header, setHeader] = useState<IHeader>();
    const [candidates, setCandidates] = useState<ICandidate[]>([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [attType, setAttType] = useState("");
    const [workLocation, setWorkLocation] = useState("");
    const [attOnOff, setattOnOff] = useState(false);
    const [filterCondition, setFilterCondition] = useState("");
    const [db, setDB] = useState([]);
    const [holiday, setHoliday] = useState([]);
    const [leaves, setLeaves] = useState([]);
    const [fetchingAttedanceStatus, setFetchingAttedanceStatus] = useState(true);
    const [selectedTest, setSelectedTest] = useState<any>();
    const [isTestSelected, setisTestSelected] = useState(false);
    const [isCandidateProgress, setCandidateProgress] = useState(false);
    
    const styles = useStyles({});
    const notify = useNotify();

    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const myclasses = myStyles({});

    const handleAttendance = (event) => {
        if (workLocation === null || workLocation === "") {
            notify("Please Select Work location", "warning")
            return;
        }
        if (attType === "ATT_END" && window.confirm('Do you really want to end your working?')) {
            updateAttedance();
        }
        if (attType === "ATT_START" && window.confirm('Do you really want to Start your working?')) {
            updateAttedance();
        }
    }
    const updateAttedance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        let formBody = {
            userId: userid,
            attType: attType,
            location: workLocation
        }
        apiClient.updateAttendance(formBody).then(res => {
            notify(res[0].Msg)
            loadAttendance();
        }).catch(err => {
            console.log(err);
        });
    }
    const loadAttendance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        apiClient.get_attendancestatus(userid).then(res => {

            setAttType(res[0].Msg);
            setWorkLocation(res[0].location)
            if (res[0].Msg === 'ATT_START') {
                setattOnOff(false)
            }
            if (res[0].Msg === 'ATT_END') {
                setattOnOff(true)
            }

            setFetchingAttedanceStatus(false);
        }).catch(err => {
            //alert(JSON.stringify(err))
            console.log(err);
        });
    }
    const getDashboard = (user: number) => {
        apiClient.GetDashboard(user).then(res => {
            // console.log(res);
            setDB(res);
        }).catch(err => {
            console.log(err);
        });
    }

    const searchCandidates = () => {
        setPageIndex(1);
        setCandidates([]);
        setLoading(true);
        apiClient.getCandidates(uid, roletypeid, filterCondition, 1, pageSize).then(res => {
            setCandidates(res);
            setPageIndex(2);
            setLoading(false);
            if (res.length == 20)
                setHasMore(true);
            else
                setHasMore(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const getCandidates = () => {
        let uid = localStorage.getItem("userId");
        apiClient.getCandidates(uid, roletypeid).then(res => {
            console.log(res);
            setCandidates(res);
            setPageIndex(pageIndex + 1);
            if (res.length == 20)
                setHasMore(true);
            else
                setHasMore(false);
        }).catch(err => {
            console.log(err);
        });
    }
    const onInnerSelectTest = async (item:any) => {
        let ResponseID = item.ResponseID;
        let Subject = item.Subject;
        let UserID = item.UserID;

        let res = await apiClient.getTestResponseEmailLog(ResponseID,UserID,Subject);
        console.log(res);
        setSelectedTest(res); 
        setisTestSelected(true);
    } 

    const loadMoreCandidates = () => {
        setLoading(true);
        apiClient.getCandidates(uid, roletypeid, filterCondition, pageIndex, pageSize,).then(res => {
            let carr = [...candidates];
            carr = carr.concat(res);
            setCandidates(carr);
            setPageIndex(pageIndex + 1);
            setLoading(false);
            if (res.length == 20)
                setHasMore(true);
            else
                setHasMore(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const GetDashboardLeaves = (user: number) => {
        setLeaves([]);
        apiClient.GetDashboardLeaves(user, filterCondition).then(res => {
            //console.log(res);
            setLeaves(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const GetDashboardHoliday = () => {
        apiClient.GetDashboardHoliday().then(res => {
            //console.log(res);
            setHoliday(res);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleClose = useCallback(() => {
        setisTestSelected(false);
        setCandidateProgress(false);
    }, []);

    useEffect(() => {
        let uid = localStorage.getItem("userId");
        let type = localStorage.getItem("type");
        let rtypeid = localStorage.getItem("RoleTypeId");
        setRoletypeid(rtypeid);
        setRole(type!);
        
        if (type == "HR Team" || type == "System") {
            GetDashboardLeaves(0);
            setUid(0);
        } else {
            GetDashboardLeaves(parseInt(uid!));
            setUid(parseInt(uid!));
        }
    }, []);

    useEffect(() => {
        getCandidates();
        let uid = localStorage.getItem("userId");
        getDashboard(parseInt(uid!));
    }, [roletypeid]);

    return (
        <div>
            <div className={styles.flexCenter}>
                {db && db.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <CountBlock title={item.TypeofEmp} value={item.TotalCount} icon={item.IconUrl} />
                        </div>
                    )
                })}
            </div>

            <div className={styles.singleCol}>
                {<h3 style={{ display: "inline-block", marginRight: 20, }}>Latest Candidates</h3>}
                {<div className="candidate-search-parent">
                    <TextField label="Search"
                        value={filterCondition}
                        onChange={(event) => { setFilterCondition(event.target.value); }}
                        variant="outlined" />
                    <Button variant="contained"
                        onClick={() => { searchCandidates(); }}
                        color="primary">
                        Search
                    </Button>

                    <Button variant="contained"
                        onClick={() => { setCandidateProgress(true) }}
                        color="primary">
                        View Candidate Progress
                    </Button>
                </div>}
                {<LatestCandidates candidates={candidates} onSelectTest={(item: any) => { onInnerSelectTest(item); }} />}
                {!loading && hasMore && <div className="load-more-btn"
                    onClick={() => { loadMoreCandidates() }}>Load More</div>}
            </div>

            <Drawer
                anchor="right"
                onClose={handleClose}
                classes={{
                    paper: myclasses.drawerPaper,
                }}
                open={isTestSelected || isCandidateProgress} >
                {selectedTest && isTestSelected && <div className="test-details" dangerouslySetInnerHTML={{ __html: selectedTest[0].Result }}></div>}
                {isCandidateProgress &&  
                        <CandidateProgress
                            onClose={handleClose}
                            onCancel={handleClose} 
                             />}
                
            </Drawer>
        </div>
    );
};

export default Dashboard;
