import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
  DateInput,
  required,
  BooleanInput
} from "react-admin";
import * as apiClient from "../../apiClient";
import { FormToolbar } from "../../common/FormToolbar";

export const EmployeeLeaveCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  let employeeId: any
  employeeId = localStorage.getItem("userId");
  if (employeeId) {
    employeeId = parseInt(employeeId);
  }
  let approverId: any
  approverId = localStorage.getItem("ApproverId");
  if (approverId) {
    approverId = parseInt(approverId);
  }
  const initialValues = {
    employeeId: employeeId,
    approverId: 0,
    isApproved: false,
    approverRemarks: "",
    isActive: true,
    remarks: "",
    totalApprovedDays: 0

  }
  const handleSubmit = (formdata) => {
    let userId: any
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    formdata.createdBy = userId;
    formdata.createdDate = new Date();
    formdata.approvedFromDate = formdata.fromDate
    formdata.approvedToDate = formdata.toDate
    formdata.totalApprovedDays=formdata.totalDays
    formdata.employeeId=userId;

    if (new Date(formdata.fromDate) > new Date(formdata.toDate)) {
      notify("To date cannot be less than from date", "warning")
      return;
    }
    apiClient.addEntiyRecord("employee-apply-leaves", formdata).then(res => {
      notify("Leave is created")
      redirect("/employee-apply-leaves");
    }).catch(err => {
      console.log(err);
    });

  }
  return (
    <Create title="Leave Create" {...props}>
      <SimpleForm onSubmit={handleSubmit} initialValues={initialValues} redirect="list"   toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined" >
        <ReferenceInput label="Leave Type" source="leaveTypeId" reference="leave-types" fullWidth={true} validate={[required()]} >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput variant="outlined" label="From Date" source="fromDate" fullWidth={true} validate={[required()]} />
        <DateInput variant="outlined" label="To Date" source="toDate" fullWidth={true} validate={[required()]} />
        <NumberInput variant="outlined" label="Total Days" source="totalDays" fullWidth={true} validate={[required()]} />
        <TextInput source="remarks" label='Remarks' fullWidth={true} />  
      </SimpleForm>
    </Create>
  );
};
