import React, { useCallback,useState,useEffect } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";
import * as apiClient from "../../apiClient";
const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));



export const ModuleEdit = (props) => {

    const [statues, setStatues] = useState(null);

    useEffect(() => {
        loadStatus();
    }, []);
    const loadStatus = () => {
        apiClient.get_data ("statuses?filter[where][id]=96").then(res => {
            setStatues(res);
        })
    }
    
    return (<Edit {...props} title="Selection Process" redirect={false} successMessage="Selection Process updated" undoable={false}>
            <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />}   
            variant="outlined">
               
           <TextInput source="name" label="Name" fullwidth={true}/>
            <TextInput source="description" label="Description"  fullwidth={true}/>
            <div style={{width:"100%"}} ><h4>System Fields for mail content:-</h4></div>
                <div style={{width:"100%"}} >
                {statues!=null && statues[0]?.statusDescription}
                </div>
            <RichTextInput source="candidateMailContents" label="Candidate Mail Contents" />
            <RichTextInput source="returnMailContents" label="Return Mail Contents" />
            <RichTextInput source="internalMailContents" label="Internal Mail Contents" /> 
            <BooleanInput initialValue={true} source="isApplicableForCandidate" label="Is Applicable For Candidate"  fullwidth={true} />
            <BooleanInput initialValue={true} source="isApplicableForEmployee" label="Is Applicable For Employee"  fullwidth={true} /> 
            <BooleanInput initialValue={true} source="isAssignedToEvaluator" label="Assign Evaluator"  fullwidth={true} />
            <BooleanInput initialValue={true} source="isActive" label="Is Active"  fullwidth={true} /> 
            </SimpleForm>
        </Edit>)
  ;
}