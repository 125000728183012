import React, { useState, useEffect } from 'react';
import { Edit, Button, Create, SimpleForm, TextInput,useNotify, ReferenceInput, SelectInput, required ,Toolbar, SaveButton} from 'react-admin';
import { useForm } from 'react-final-form';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import TextField from '@material-ui/core/TextField';
const ChangePasswordCreateToolbar = props => {
    const form = useForm();
    const saveData = (formdata) => {
        
            let user: any = localStorage.getItem("userId");
            if (user) {
            user = parseInt(user);
            }
        //console.log(formdata);
        let data = {
            existing_password: formdata.oldpassword,
            new_password: formdata.newpassword,
            confirmpassword: formdata.confirmpassword,
            userId:user

        };
        props.setMessage("");
        apiClient.ChangePassword(data).then((res) => {
            if (res[0].result) {
                props.setMessage("Password changed successfully");
                form.change("oldpassword", "");
                form.change("newpassword", "");
                form.change("confirmpassword", "");
            } else {
                props.setMessage(res[0].message);
            }
        });
    }
    
    return (
        <Toolbar {...props} >
            <SaveButton  {...props} label="Change password" onSave={saveData}/>
        </Toolbar>
    )
};

export const ChangePassword = props => {
    const [message, setMessage] = useState("");  
    const [oldPassword, setoldPassword] = useState("");  
    const [newPassword, setnewPassword] = useState("");  
    const [confirmPassword, setconfirmPassword] = useState("");  
    const notify = useNotify();
    const handleSubmit = (formdata) =>  {
       
        let user: any = localStorage.getItem("userId");
        if (user) {
        user = parseInt(user);
        }
    //console.log(formdata);
    if(oldPassword===null || oldPassword==="")
    {
        notify("Please enter old passowrd ","warning")
        return
    }
    if(newPassword===null || newPassword==="")
    {
        notify("Please enter new passowrd ","warning")
        return
    }
    if(confirmPassword===null || confirmPassword==="")
    {
        notify("Please enter confirm passowrd ","warning")
        return
    }
    let data = {
        existing_password: oldPassword,
        new_password: newPassword,
        confirmpassword: confirmPassword,
        userId:user

    };
    setMessage("");
    apiClient.ChangePassword(data).then((res) => {
        if (res[0].result) {
            //setMessage("Password changed successfully");
            notify("Password has been changed")
            setoldPassword("")
            setnewPassword("")
            setconfirmPassword("")
            
        } else {
            setMessage(res[0].message);
        }
    });
}

    return (
        <React.Fragment>
            <Create title="Change Password" {...props} basePath="changepassword" resource="changepassword">
                <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<CreateToolbar label="Change Passowrd" onSave={handleSubmit} />}
                variant="outlined"> 
                    <TextField id="Old Password" value={oldPassword} type="password" label="Old Password" variant="outlined" required onChange={(event)=>setoldPassword(event.target.value)} />
                    <TextField id="newpassword"  value={newPassword} type="password" label="New Password" variant="outlined" required onChange={(event)=>setnewPassword(event.target.value)}/>
                    <TextField id="confirmpassword" value={confirmPassword} type="password" label="Confirm Password" variant="outlined" required onChange={(event)=>setconfirmPassword(event.target.value)}/>
                </SimpleForm>
            </Create>
            {message && <div className="change-password-result">{message}</div>}
        </React.Fragment>
    );


}