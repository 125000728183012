import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const ModuleCreate = props => (
    <Create {...props} title="Nationality" redirect={false} successMessage="Nationality updated" undoable={false}>
            <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />} 
            variant="outlined">
            <TextInput source="name" label="Nationality Name"/>
            <TextInput source="description" label="Description"/>
            <BooleanInput initialValue={true} source="isActive" label="Is Active" />
            </SimpleForm>
        </Create>
    );
