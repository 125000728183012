import React, { useState, useEffect } from 'react';
import { Create, SimpleForm, TextInput, BooleanInput, DateInput, useNotify, NumberInput, required, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import * as apiClient from "../../apiClient";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
//import { EmployeeSubjectExpertCreate } from '../employee/EmployeeSubjectExpertCreate';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const EmployeeListReport = (props: any) => {
    const classes = useStyles();
   
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState<any>(0);
    const [employeeType, setEmployeeType] = useState<any>(0);
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState<any>(0);
    const [designations, setDesignations] = useState<any>([]);
    const [designation, setDesignation] = useState<any>(0);
    const [leavecategories, setLeavecategories] = useState<any>([]);
    const [leavecategory, setLeavecategory] = useState<any>(0);
    const [shifts, setShifts] = useState<any>([]);
    const [shift, setShift] = useState<any>(0);
    const [employeeStatus, setEmployeeStatus] = useState<any>(0);
    const [email, setEmail] = useState<string>("");
    const [mobile, setMobile] = useState<string>("");
    const [cols, setCols] = useState<string[]>([]);

    const [data, setData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState<any>(0);
    const [fectched, setFectched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getEmployees = () => {
        apiClient.getEmployees().then(res => {
            //console.log(res);
            setEmployees(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getDepartments = () => {
        apiClient.getDepartments().then(res => {
            //console.log(res);
            setDepartments(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getDesignations = () => {
        apiClient.getResource("designations").then(res => {
            setDesignations(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getLeaveCategories = () => {
        apiClient.getResource("leave-categories").then(res => {
            setLeavecategories(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getShifts = () => {
        apiClient.getResource("shifts").then(res => {
            setShifts(res);
        }).catch(err => {
            console.log(err);
        });
    }

    const getRoleTypes = () => {
        apiClient.getRoleTypes().then(res => {
            console.log(res);
            setRoles(res);
        }).catch(err => {
            console.log(err);
        });
    }

    const generateReport = () => {
        setFectched(false);
        setIsLoading(true);
        let data = {
            EmployeeTypeID: parseInt(employeeType),
            EmployeeID: parseInt(employee),
            Email: email,
            MobileNo: mobile,
            DepartmentID: parseInt(department),
            RoleTypeID: parseInt(role),
            DesignationID: parseInt(designation),
            LeaveCategoryID: parseInt(leavecategory),
            ShiftID: parseInt(shift),
            StatusID: parseInt(employeeStatus),

        };

        apiClient.getEmployeeListReport(data).then(res => {
            //console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                setCols(keys);
            }
            setIsLoading(false);

        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        let n = localStorage.getItem('username');
        if(n == null){
            props.history.push("/login");
        }
        
        getEmployees();
        getDepartments();
        getRoleTypes();
        getDesignations();
        getLeaveCategories();
        getShifts();
    }, []);


    return (
        
        <SimpleForm toolbar={null}>
       <div style={{width:"100%"}}>
        <div className="employee-list-report">
            <div className="filters">
                <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label"> Type</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setEmployeeType(e.target.value); }}>
                        <MenuItem value="0">--Select--</MenuItem>
                        <MenuItem value={46}>Candidate</MenuItem>
                        <MenuItem value={45}>Freelancer</MenuItem>
                        
                    </Select>
                </FormControl>
                <FormControl size="small" style={{marginTop:-10}} className={classes.formControl} variant="outlined">
                <ReferenceInput label={"Freelancer/Candidate"}
                            perPage={100} source="employeeId" 
                            reference="candidates" 
                            filter={{ typeId: employeeType?parseInt(employeeType):0 }}
                            onChange={(e) => { setEmployee(e.target.value); }}
                            >
                            <AutocompleteInput optionText="firstName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}  />
                        </ReferenceInput>  
                        </FormControl>
                {/* <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Employee</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setEmployee(e.target.value); }}>
                        {employees && employees.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.firstName} {item.lastName}</MenuItem>)
                        })}
                    </Select>
                </FormControl> */}
                {/* <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Department</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setDepartment(e.target.value); }}>
                        {departments && departments.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl> */}
                <TextField size="small" label="Email" variant="outlined" onChange={(e) => { setEmail(e.target.value); }} />
                <TextField size="small" label="Mobile" variant="outlined" onChange={(e) => { setMobile(e.target.value); }} />
                {/* <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setRole(e.target.value); }}>
                        {roles && roles.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.roleName}</MenuItem>)
                        })}
                    </Select>
                </FormControl> */}
                {/* <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Designation</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setDesignation(e.target.value); }}>
                        {designations && designations.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl> */}
                {/* <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Leave Category</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setLeavecategory(e.target.value); }}>
                        {leavecategories && leavecategories.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl> */}
                <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Shifts</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setShift(e.target.value); }}>
                        {shifts && shifts.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Active / InActive</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setEmployeeStatus(e.target.value); }}>
                        <MenuItem value="0">--Select--</MenuItem>
                        <MenuItem value="1">Active</MenuItem>
                        <MenuItem value="2">In Active</MenuItem>
                    </Select>
                </FormControl>
                {fectched && data.length > 0 && <div>Total {data.length} employees found</div>}
                {fectched && data.length == 0 && <div>No employees found</div>}
                <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"Employee_list.csv"} data={data}>Download Report</CSVLink>}
                </div>
            </div>
            <div className="filter-action">
                <Button variant="contained"
                    onClick={() => {
                        generateReport();
                    }}
                    startIcon={<AssessmentIcon />}
                    color="primary">
                    Generate Report
                </Button>
            </div>
            {isLoading && <CircularProgress />}
            <div className="report-data">
                <div className="w3-responsive">
                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">
                        <tr>
                            {cols && cols.map((item, index) => {
                                return (
                                    <th key={item}>{item}</th>
                                )
                            })}
                        </tr>

                        {data && data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {cols && cols.map((col, colIndex) => {
                                        return (
                                            <td key={rowIndex + "-" + colIndex}>
                                                {row[col]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        </div>
        </div>
        </SimpleForm>
        
    );
};