import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

export const CandidateReportingCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true,
        date: new Date()
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let date = (formdata.date) ? moment(formdata.date).format() : null;
        let data = {
            employeeId: parseInt(props.employeeId),
            date: date,
            firstReportingEmpId: formdata.firstReportingEmpId,
            secondReportingEmpId: formdata.secondReportingEmpId,
            remarks: formdata.remarks? formdata.remarks:"",
            isActive: formdata.isActive
        }

        apiClient.createCandidateReporting(data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <DateInput  validate={required()} source="date" fullWidth={true} label="Effective From" />
                <ReferenceInput perPage={50000} validate={required()} label="First Reporting Candidate" 
                sort={{ field: 'firstName', order: 'ASC' }}
                    source="firstReportingEmpId" reference="employees"
                    fullWidth={true}>
                    <SelectInput optionText={choice =>
                        `${choice?.firstName} ${choice?.lastName}`
                    } />
                    
                    
                </ReferenceInput> 
          

                <ReferenceInput perPage={50000} label="Second Reporting Candidate" validate={required()}
                    source="secondReportingEmpId" reference="employees"
                    sort={{ field: 'firstName', order: 'ASC' }}
                    
                    fullWidth={true}>
                    <SelectInput optionText={choice =>
                        `${choice?.firstName} ${choice?.lastName}`
                    } />
                </ReferenceInput>

                <TextInput fullWidth={true} multiline source="remarks" label="Remarks" />
                <BooleanInput source="isActive" label="Is Active" />
            </SimpleForm>
        </Edit>
    );
}
