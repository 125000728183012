import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField,Filter,TextInput
}
    from 'react-admin';

export const ModuleList = props => {
    const Filters = props => (
        <Filter {...props}>
            <TextInput label="Name" source="name~like" alwaysOn variant="outlined"/>
            {/* <TextInput label="Email" source="email~like" alwaysOn />
            <TextInput label="Mobile" source="mobile~like" alwaysOn /> */}
        </Filter>
    );
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
    return (
        <List title="Technical Skils" {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}  filters={<Filters />}  >
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
                {propsObj.hasEdit && <EditButton />}
            <TextField source="name" label="Technical Skill Name"/>
            <TextField source="description" label="Description"/>
            <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="firstName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date"/>
            <ReferenceField source="modifyBy" reference="users" label="Modified By"  link={false}>
                <TextField source="firstName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Modified Date"/>
        </Datagrid>
    </List>
    )
};