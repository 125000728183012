import React, { useState, useEffect } from 'react';
import * as apiClient from "../../apiClient";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const EmployeeReport = (props: any) => {
   

    const classes = useStyles();
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState<any>("");
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState<any>("");
    const [designations, setDesignations] = useState<any>([]);
    const [designation, setDesignation] = useState<any>("");
    const [leavecategories, setLeavecategories] = useState<any>([]);
    const [leavecategory, setLeavecategory] = useState<any>("");
    const [shifts, setShifts] = useState<any>([]);
    const [shift, setShift] = useState<any>("");
    const [email, setEmail] = useState<string>("");
    const [mobile, setMobile] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState<any>("");

    const [report, setReport] = useState("http://52.172.190.126:5488/templates/BJlLbBq5KI");
    const [pdfReport, setPdfReport] = useState("http://52.172.190.126:5488/templates/Syl4aCwJo8");
    
    const getEmployees = () => {
        apiClient.getEmployees().then(res => {
            //console.log(res);
            setEmployees(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getDepartments = () => {
        apiClient.getDepartments().then(res => {
            //console.log(res);
            setDepartments(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getDesignations = () => {
        apiClient.getResource("designations").then(res => {
            setDesignations(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getLeaveCategories = () => {
        apiClient.getResource("leave-categories").then(res => {
            setLeavecategories(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getShifts = () => {
        apiClient.getResource("shifts").then(res => {
            setShifts(res);
        }).catch(err => {
            console.log(err);
        });
    }

    const getRoleTypes = () => {
        apiClient.getRoleTypes().then(res => {
            console.log(res);
            setRoles(res);
        }).catch(err => {
            console.log(err);
        });
    }

    const generateReport = () => {
        let param = "type=html";
        if (employee != "") {
            param += "&EmployeeID=" + employee;
        }
        if (department != "") {
            param += "&DepartmentID=" + department;
        }
        if (designation != "") {
            param += "&DesignationID=" + designation;
        }
        if (role != "") {
            param += "&RoleTypeID=" + role;
        }
        if (email != "") {
            param += "&Email=" + email;
        }
        if (mobile != "") {
            param += "&MobileNo=" + mobile;
        }
        setIsLoading(true);
        setReport("http://52.172.190.126:5488/templates/BJlLbBq5KI?" + param);
        setPdfReport("http://52.172.190.126:5488/templates/Syl4aCwJo8?" + param);
        setIsLoading(false);
    }
    useEffect(() => {
        let n = localStorage.getItem('username');
        if(n == null){
            props.history.push("/login");
        }
        getEmployees();
        getDepartments();
        getRoleTypes();
        getDesignations();
        getLeaveCategories();
        getShifts();
    }, []);


    return (
        <div className="employee-report">
            <div className="filters">
                <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Employee</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setEmployee(e.target.value); }}>
                        {employees && employees.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.firstName} {item.lastName}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Department</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setDepartment(e.target.value); }}>
                        {departments && departments.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <TextField size="small" label="Email" variant="outlined" onChange={(e) => { setEmail(e.target.value); }} />
                <TextField size="small" label="Mobile" variant="outlined" onChange={(e) => { setMobile(e.target.value); }} />
                <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setRole(e.target.value); }}>
                        {roles && roles.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.roleName}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Designation</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setDesignation(e.target.value); }}>
                        {designations && designations.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Leave Category</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setLeavecategory(e.target.value); }}>
                        {leavecategories && leavecategories.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl size="small" className={classes.formControl} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Shifts</InputLabel>
                    <Select variant="outlined" onChange={(e) => { setShift(e.target.value); }}>
                        {shifts && shifts.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className="filter-action">
                <Button variant="contained"
                    onClick={() => {
                        generateReport();
                    }}
                    startIcon={<AssessmentIcon />}
                    color="primary">
                    Generate Report
                </Button>
                <Button variant="contained" style={{ marginLeft: 15, }}
                    onClick={() => {
                        generateReport();
                    }}
                    href={pdfReport} target="_blank"
                    startIcon={<PictureAsPdfIcon />}
                    color="primary">
                    View as PDF
                </Button>
            </div>
            {isLoading && <CircularProgress />}
            <iframe src={report} className="report-view"></iframe>
        </div>
    );
};