import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
    },
    timerContainer: {
        display: 'flex',
        justifyContent: "space-between"
    },
    minutes: {
        color: "#ff0000",
        fontWeight: "bold",
        fontSize: 20,
    },
    devider: {
        margin: "0px 10px",
    },
    seconds: {
        color: "#ff0000",
        fontWeight: "bold",
        fontSize: 20,
    },
});

const QuizTimer = (props: any) => {
    const [timeMinsLeft, setTimeMinsLeft] = useState(props.duration - 1);
    const [timeSecLeft, setTimeSecLeft] = useState(60);
    const classes = useStyles({});

    useEffect(() => {
        setTimeout(() => {
            // console.log("Sec : " + timeSecLeft);
            if (timeSecLeft == 1) {
                //   console.log(" InSec : " + timeSecLeft);
                setTimeMinsLeft(timeMinsLeft - 1);
                if (timeMinsLeft == 0) {
                    props.onTimerComplete();
                }
                setTimeSecLeft(60);
                //console.log("In Sec 1 : " + timeSecLeft);
            } else {
                setTimeSecLeft(timeSecLeft - 1);
            }
        }, 1000);
    }, [timeSecLeft]);
    return (
        <div>
            <div className={classes.timerContainer}>
                <div className={classes.minutes}>{timeMinsLeft}</div>
                <div className={classes.devider}>:</div>
                <div className={classes.seconds}>{timeSecLeft}</div>
            </div>
        </div>

    );
}
export default QuizTimer;
