import React from 'react';
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, Button, FormTab,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField
} from 'react-admin';
import { useStyles } from "../../formStyles";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { Link } from '@material-ui/core';
import { CreateToolbar } from '../../CreateToolbar';

export const UserEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props} >
            <SimpleForm toolbar={<CreateToolbar {...props} mode={"Edit"} />}
                defaultValue={{ middleName: "", pwd: "", roleId: 3, middleName: "", lastName: "",address:"",pinCode:"",status:true }}
                variant="outlined">
                <TextInput source="userName" validate={[required(), ]} fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="initials" fullWidth={true} formClassName={classes.last_inline_input} />
                <TextInput source="firstName" fullWidth={true} validate={required()}  formClassName={classes.one_three_input} />
                <TextInput source="middleName" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="lastName" fullWidth={true} validate={required()}  formClassName={classes.last_three_input} />
                <TextInput source="email" fullWidth={true} validate={[required(), email()]} formClassName={classes.first_inline_input} />
                <TextInput source="mobile" fullWidth={true} validate={required()} formClassName={classes.last_inline_input} />
                <TextInput source="address" fullWidth={true} />
                <TextInput source="pinCode" fullWidth={true} formClassName={classes.first_inline_input} />
                <ReferenceInput label="User Role" source="roleId" reference="role-types" 
                fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="roleName" />
                </ReferenceInput>
                <TextInput source="pwd" fullWidth={true} validate={[required(), minLength(8), maxLength(15)]} />
                <BooleanInput source="status" />
            </SimpleForm>
        </Edit>
    );
}