import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import * as apiClient from "../../apiClient";
import { ISubject } from "../../model/ISubject";
import noimagefound from "../../images/noimagefound.png";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
    },
    subjectsContainer: { display: 'flex', flexWrap: "wrap", justifyContent: "center", alignItems: "center" },
    subject: {
        width: 150, margin: 20,
        display: "flex", justifyContent: "center", alignItems: "flex-end",
        marginBottom: 20, border: "1px solid #cccccc", backgroundColor: "#eeeeee",
    },
    subjectImage: {
        maxWidth: "100%",
        width: "100%",
        boxSizing: "border-box",
        padding: 10,
    },
    message: {
        textAlign: "center",
        color:"#006622",
    },
    subjectName: {
        textDecoration: "none", color: "#000", marginBottom: 10,
        fontWeight: "bold", textAlign: "center"
    },
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
});

const QuizResult = () => {
    const [msg, setMsg] = useState<string>();
    const classes = useStyles({});

    const getMessage = () => {
        let rid = localStorage.getItem("testresult");
        apiClient.GetRestResponse(rid).then((response) => {
            console.log(response);
            setMsg(response.testresultmsg);
        });
    }
    getMessage();

    return (
        <div className={classes.root}>
            <h1>Test Result</h1>
            <div className={classes.subjectsContainer}>
                <h2 className={classes.message} dangerouslySetInnerHTML={{__html:msg!}} ></h2>
            </div>
        </div>
    );
};

export default QuizResult;
