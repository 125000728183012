import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ImageField, RichTextField
} from 'react-admin';

export const QuestionOptionList = props => (
    <List { ...props }>
        <Datagrid rowClick="show">
            <NumberField source="optionNumber" label="Option Number" />
            <TextField source="optionTitle" label="Option Title" />
            <RichTextField source="optionText" label="Option Text" />
            <ImageField source="optionImage" label="Option Image" />
            <BooleanField source="isCorrect" label="Is Correct" />
            <EditButton />
        </Datagrid>
    </List>
);