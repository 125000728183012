import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";


export const CandidateDepartmentCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true,
        date: new Date()
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let date =(formdata.date) ? moment(formdata.date).format() : null;
        let userid=0 ;
        userid =localStorage.getItem("userId");
        if(userid)
        {
            userid=parseInt(userid);
        }
        //debugger

        date=date.toString().replace("+05:30","");
        let _date=date.toString().replace('+5:30','')
        
        let data = {
            employeeId: parseInt(props.employeeId),
            date: date,
            departmentId: formdata.depart_Id,
            fromDate: formdata.startDate,
            toDate: formdata.toDate,
            remarks: formdata.remarks? formdata.remarks:"",
            isActive: formdata.isActive,
            createdBy:userid,
            createdDate:date,
            modifyBy:userid,
            modifyDate:date
        }

        apiClient.createCandidateDepartment(data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <DateInput source="startDate"  fullWidth={true} label="Start Date" />
                <DateInput source="toDate"  fullWidth={true} label="End Date" />
                <ReferenceInput label="Department" validate={required()} 
                    source="depart_Id" reference="departments"  sort={{ field: 'name', order: 'ASC' }}  
                    fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput fullWidth={true}  multiline source="remarks" label="Remarks" initialValue={""} />
                <BooleanInput source="isActive" label="Is Active" />
            </SimpleForm>
        </Edit>
    );
}
