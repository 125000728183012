import React from 'react';
import {
    List, Datagrid, TextField, DateField,RichTextField,
    ReferenceField, ImageField,
    NumberField, BooleanField, EditButton
} from 'react-admin';

export const QuestionList = props => (
    <List {...props} title="Test questions" sort={{ field: 'questionNumber', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Test" source="testId" reference="tests">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Subject" source="subjectId" reference="subjects">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Question Type" source="questionTypeId" reference="questiontypes">
                <TextField source="questionTypeName" />
            </ReferenceField>
            <RichTextField source="question" label="Question" />
            <BooleanField source="mustAnswer" label="Must Answer" />
            <ImageField source="logoUrl" label="Icon" />
            <BooleanField source="isActive" label="Is Active" />
            <NumberField source="noOfChoice" label="Choice Available" />
            <NumberField source="questionNumber" label="Question Number" />
        </Datagrid>
    </List>
);