import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    TabbedForm, Button,
    FormTab,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField
} from 'react-admin';
import { useStyles } from "../../formStyles";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { Link } from '@material-ui/core';
import AddNewQuestionButton from "./AddNewQuestionButton";
import CustomLogoInputDisplay from "../../CustomImage";
import { CreateToolbar } from "../../CreateToolbar";
/* const AddNewQuestionButton = ({ record }) => (
    <Button
      component={Link}
      to={{
        pathname: "/testquestions/create",
        state: { test_id: record.id },
      }}
      label="Add a Question"
    >
      <ChatBubbleIcon />
    </Button>
  ); */

export const TestEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props}>
            <TabbedForm variant="outlined" toolbar={<CreateToolbar {...props} />}>
                <FormTab label="Test Details">
                    <ReferenceInput label="Subject" validate={required()} source="subjectId" reference="subjects" fullWidth={true} formClassName={classes.first_inline_input}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="name" validate={required()} label="Test Name" fullWidth={true} formClassName={classes.last_inline_input} />
                    <TextInput source="title" validate={required()} lable="Topics" fullWidth={true}   formClassName={classes.first_inline_input} />
                    <ReferenceInput label="Test Type" validate={required()} source="testTypeId" reference="testtypes" fullWidth={true} formClassName={classes.last_inline_input}>
                        <SelectInput optionText="testTypeName" />
                    </ReferenceInput>
                    <ImageInput source="logoUrl" label="Icon" accept="image/*" className="logourl">
                        <ImageField source="logoUrl" title="Icon" />
                    </ImageInput>
                    <CustomLogoInputDisplay field="logoUrl" />
                    <NumberInput source="testDuration" validate={required()} label="Test Duration" fullWidth={true} formClassName={classes.one_three_input} />
                    <NumberInput source="passingPercentage" validate={required()} label="Passing Percentage" fullWidth={true} formClassName={classes.two_three_input} />
                    <NumberInput source="noOfQuestions" validate={required()} label="No of questions" fullWidth={true} formClassName={classes.last_three_input} />
                    <BooleanInput initialValue={true} source="isActive" label="Is Active" />
                </FormTab>
                <FormTab label="Questions">
                <AddNewQuestionButton />
                    <ReferenceManyField perPage={100} reference="testquestions" target="testId" addLabel={false} fullWidth={true} sort={{ field: 'questionnumber', order: 'ASC' }}>
                        <Datagrid>
                            <EditButton />
                            <ReferenceField link={false} label="Subject" source="subjectId" reference="subjects">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField link={false} label="Question Type" source="questionTypeId" reference="questiontypes">
                                <TextField source="questionTypeName" />
                            </ReferenceField>
                            <RichTextField source="question" label="Question" />
                            <BooleanField source="mustAnswer" label="Must Answer" />
                            <ImageField source="logoUrl" label="Icon" />
                            <NumberField source="noOfChoice" label="Choice Available" />
                            <NumberField source="questionNumber" label="Question Number" />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}