import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const CityCreate = props => (
    <Create {...props} title="City" redirect={false} successMessage="City updated" undoable={false}>
            <SimpleForm redirect="list"  toolbar={<CreateToolbar {...props} />} 
            variant="outlined">
            <TextInput validate={required()} source="cityName" label="City Name"/>
            <ReferenceInput source="statesId" reference="states">
                <SelectInput optionText="stateName" />
            </ReferenceInput>
            </SimpleForm>
        </Create>
    );
