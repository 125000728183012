import React from 'react';
import Button from '@material-ui/core/Button';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import * as apiClient from "../../apiClient";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { ISubject, ITest } from '../../model/ISubject';
import noimagefound from "../../images/noimagefound.png";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
    },
    subjectsContainer: { display: 'flex', flexDirection: "column", },
    subject: {
        margin: 20,
        display: "flex", justifyContent: "center",
        marginBottom: 20, border: "1px solid #cccccc",
    },
    subjectImage: {
        maxWidth: "100%",
        padding: 10,
    },
    subjectaction: { display: "flex", justifyContent: "space-between", flexDirection:"column", margin:"20px 0px", },
    subjectName: { fontWeight: "bold", textAlign: "center" },
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
    testparent:{display:"flex", flexDirection:"row",alignItems:"center",justifyContent: "space-between",},
    testname:{},
    testactions:{},
});

export interface IMTest {
    ID: number;
    subjectid: number;
    Name: string;
    Title: string;
    testtypeid: number;
    logourl: string;
    isactive: boolean;
    testduration: number;
    passingpercentage: number;
    noofquestions: number;
    publishedby: number;
    publishdate: string;
    enddate: string;
    createdby: number;
    createddate: string;
    modifyby: number;
    modifydate: string;
}
const StudentSubjectDetail = (props: any) => {
    const [subject, setSubject] = useState<ISubject | any>(null);
    const [tests, setTests] = useState<IMTest[]>([]);
    const [userId, setUserId] = useState(0);
    const classes = useStyles({});
    let { id } = useParams<any>();

    const getSubject = () => {
        apiClient.GetSubject(id).then(response => {
            setSubject(response);
        }).catch(error => {
            console.log(error);
        });
    }
    const getTestsForSubject = (userId:number) => {
        /* apiClient.getAllTestForSubject(id).then(response => {
            //console.log(response);
            setTests(response);
        }).catch(error => { console.log(error); }); */
        apiClient.GetAllSubjectTestsByUserID(userId,id).then(response => {
            //console.log(response);
            setTests(response);
        }).catch(error => { console.log(error); });
    }
    useEffect(() => {
        getSubject();
        let uId = localStorage.getItem("userId");
        if (uId) {
            setUserId(parseInt(uId));
            getTestsForSubject(parseInt(uId!));
        }
        
    }, []);
    return (
        <div className={classes.root}>
            {subject && <h1>{subject.name}</h1>}
            <div className={classes.subjectsContainer}>
                <div className={classes.subject}>
                    {(!subject || !subject.logourl) && <img className={classes.subjectImage} src={noimagefound} />}
                    {subject && subject.logourl && <img className={classes.subjectImage} src={subject.logourl} />}
                </div>
                <div>
                    <h2>Topics</h2>
                    <div dangerouslySetInnerHTML={{ __html: (subject.description) ? subject.description : "" }}></div>
                    <div className={classes.subjectaction}>
                        {tests && tests.map((item, index) => {
                            return (
                                <div key={index} className={classes.testparent}>
                                    <div className={classes.testname}>{item.Name} - </div>
                                    <div className={classes.testactions}>
                                        <Button className={classes.button} variant="contained" color="primary" href={"#testheader/" + item.ID}>
                                            Take Test
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentSubjectDetail;
