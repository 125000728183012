import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,FormDataConsumer,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import { AddressFields } from "../../AddressFields";
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

export const CandidateProjectEdit = props => {
    const classes = useStyles({});
    const initialValues = {
         
    }
    const handleSubmit = (formdata) => {
         
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }
        let endDate = (formdata.endDate) ? moment(formdata.endDate).format() : null;
        let data={...formdata};
        data.endDate=endDate;
        let m = moment(new Date());
        if(data.createdBy==null)
            {
                data.createdBy=userId;
            }
            if(data.endDate==null)
                {
                    delete data.endDate;
                }
            if(data.createdDate==null)
                {
                    data.createdDate= m.toISOString();
                }
 
        data. modifyDate=  m.toISOString()
        data. modifyBy=  userId
        apiClient.updateRecord("candidate-project-mappings", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="candidate-project-mappings" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <ReferenceInput label="For Project" validate={required()} source="workRoleProjectId" reference="work-role-projects"
                    fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput label="Business Unit" validate={required()} source="workRoleId" reference="work-roles"
                    fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                
                <ReferenceInput label="Subject" validate={required()} 
                source="subjectId" reference="subjects" sort={{ field: 'name', order: 'ASC' }} 
                    fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                
              
                <DateInput source="startDate"  validate={required()} fullWidth={true} label="Start Date" formClassName={classes.first_inline_input} />
                <DateInput source="endDate"   fullWidth={true} label="End Date" formClassName={classes.last_inline_input}/>
                
            </SimpleForm>
        </Edit>
    );
}
