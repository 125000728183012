import React, { useCallback } from 'react';
import {required ,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const WorkLocationEdit = props => (
    <Edit {...props} title="Edit Work Type" redirect={false} successMessage="Work Location Updated" undoable={false}>
            <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />} variant="outlined">
                <TextInput  validate={required()} source="name" label="Work Type" fullWidth={true} />
                <TextInput  validate={required()} source="description" label="Description" fullWidth={true} />
            </SimpleForm>
        </Edit>
    );
