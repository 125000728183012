import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,SelectInput,AutocompleteInput,ReferenceInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import CustomLogoInputDisplay from "../../CustomImage";
import {CreateToolbar} from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

const SubjectPaymentEdit = props => (
    <Edit {...props} title="Edit Payment" redirect={false} successMessage="Subject Updated" undoable={false}>
            <SimpleForm redirect="list" toolbar={<CreateToolbar {...props}/>}
            variant="outlined">
               <TextInput   validate={required()}  label="Payment Code"  source="name" fullWidth={true} />
                <ReferenceInput label="Payment Type" fullWidth={true}
                    perPage={5000} source="paymentTypeId" 
                    reference="statuses" 
                    filter={{ statusType: 'PAYMTYPE'}}
                    sort={{ field: 'status', order: 'ASC' }} 

                >
                    <SelectInput  optionText="status" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                {/* <ReferenceInput label="Subject" fullWidth={true}
                    perPage={5000} source="subjectId" 
                    reference="subjects" 
                    sort={{ field: 'name', order: 'ASC' }} 

                >
                    
                    <AutocompleteInput  variant="outlined" 
                optionText={choice => {
                    return (choice.name) ;
                }
                }
            />
                </ReferenceInput> */}
                <NumberInput   validate={required()} source="minRate" label={'Min Rate'}fullWidth={true} />
                <NumberInput   validate={required()} source="maxRate" label={'Max Rate'} fullWidth={true} />
                
                <BooleanInput initialValue={true}  source="isActive" />
            </SimpleForm>
        </Edit>
    );

export default SubjectPaymentEdit;