import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const ModuleCreate = props => (
    <Create {...props} title="Selection Process" redirect={false} successMessage="Selection Process updated" undoable={false}>
            <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />} 
            variant="outlined">
            <TextInput source="name" label="Name" fullwidth={true}/>
            <TextInput source="description" label="Description"  fullwidth={true}/>
  
  <BooleanInput initialValue={true} source="isApplicableForCandidate" label="Is Applicable For Candidate"  fullwidth={true} />
  <BooleanInput initialValue={true} source="isApplicableForEmployee" label="Is Applicable For Employee"  fullwidth={true} />

            <BooleanInput initialValue={true} source="isActive" label="Is Active"  fullwidth={true} />
            </SimpleForm>
        </Create>
    );
