import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput,
    BooleanInput, NumberInput
} from 'react-admin';
import { useStyles } from "../../formStyles";
import RichTextInput from 'ra-input-rich-text';
import { parse } from "query-string";
import { CreateToolbar } from "../../CreateToolbar";

export const QuestionOptionCreate = props => {
    const classes = useStyles({});
    const { question_id: question_id_string } = parse(props.location.search);
    const question_id = question_id_string ? parseInt(question_id_string, 10) : "";
    

    const redirect = question_id ? `/testquestions/${question_id}/1` : "show";
    return (
        <Create { ...props } title="Add question option">
            <SimpleForm variant="outlined" toolbar={ <CreateToolbar { ...props } /> }
                defaultValue={ { testQuestionId: question_id } }
                redirect={ redirect }>

                <ReferenceInput disabled label="question" validate={ required() } source="testQuestionId" reference="testquestions" fullWidth={ true }>
                    <SelectInput optionText="question" />
                </ReferenceInput>

                <NumberInput source="optionNumber" validate={ required() } label="Option Number" />
                <TextInput source="optionTitle" validate={ required() } label="Option Title" />
                <RichTextInput source="optionText" label="Option Text" fullWidth={ true } />
                <ImageInput source="optionImage" label="Option Image" accept="image/*" className="logourl">
                    <ImageField source="optionImage" title="Icon" />
                </ImageInput>
                <BooleanInput initialValue={ true } source="isCorrect" label="Is Correct" />
            </SimpleForm>
        </Create>
    );
}