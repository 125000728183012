import React, { useCallback } from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    required,
    useNotify,
  useRedirect,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
	AutocompleteInput,
    Create, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,FormDataConsumer
} from 'react-admin';
import { useStyles } from "../../formStyles";  
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import moment from "moment";
import { Subjects } from './Subjects';

export const NotificationCreate = props => {
    const classes = useStyles({});
    const notify = useNotify();
    const redirect = useRedirect();
    const initialValues = {
        date: new Date(),
        
    
      }
      const handleSubmit = (formdata) => {
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }
        formdata.createdBy = userId;
        formdata.createdDate = new Date();
        formdata.approvedFromDate = formdata.fromDate
        formdata.totalSent ="0";
        
        formdata.totalAccepted="0"
        formdata.totalRejected="0"
        formdata.totalPending="0" 
        formdata.modifyBy=0
        
    
        if (new Date(formdata.startDate) > new Date(formdata.endDate)) {
          notify("End date cannot be less than Start date", "warning")
          return;
        }
        formdata.startDate= moment(formdata.startDate).format()
        formdata.endDate= moment(formdata.endDate).format()
        formdata.lastRespDate= moment(formdata.lastRespDate).format()
        
        apiClient.addEntiyRecord("notifications", formdata).then(res => {
          notify("Requisition is created")
          redirect("/notifications");
        }).catch(err => {
          console.log(err);
        });
    
      }
    return (<Create {...props} title="Requisition Create" redirect={false} successMessage="Salary Head Updated" undoable={false}>
        <SimpleForm onSubmit={handleSubmit} initialValues={initialValues} redirect="list"   toolbar={<CreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined" >
        {/* <SimpleForm redirect="list"  variant="outlined">*/}
            <DateInput source="date" label="Date" fullWidth={true} formClassName={classes.one_three_input} disabled />
            <ReferenceInput validate={required()} label="For Project" source="projectId" reference="work-role-projects" fullWidth={true} formClassName={classes.two_three_input}
            sort={{ field: 'name', order: 'ASC' }}
            >
                    <SelectInput optionText="name" />
            </ReferenceInput> 
            <TextInput source="notiSubject" label="Requisition Subject" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
            <DateInput source="startDate" label="Project Start Date" fullWidth={true} formClassName={classes.one_three_input} validate={required()} />
            <DateInput source="endDate" label="Project End Date" fullWidth={true} formClassName={classes.two_three_input}validate={required()}  />
            <DateInput source="lastRespDate" label="Last Receiving Date" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
            {/* <FormDataConsumer >
                            {formDataProps => (
                                <Subjects {...formDataProps}
                                    
                                />
                            )}
                        </FormDataConsumer>  */}
            
            <ReferenceInput validate={required()} label="Business Unit" source="workRoleId" reference="work-roles" fullWidth={true} formClassName={classes.one_three_input}
              sort={{ field: 'name', order: 'ASC' }}
            >
            <AutocompleteInput
                    optionText={choice =>
                        `${choice?.name} `
                    }
                />
            </ReferenceInput> 

            <ReferenceInput validate={required()} label="Technical Skill" source="keySkillId" reference="key-skills" fullWidth={true} formClassName={classes.two_three_input}
            sort={{ field: 'name', order: 'ASC' }}
            >
            <AutocompleteInput
                    optionText={choice =>
                        `${choice?.name} `
                    }
                />
            </ReferenceInput> 
            
 

            <ReferenceInput validate={required()} label="Experience" source="experienceId" reference="experiences" fullWidth={true} formClassName={classes.last_three_input}
            sort={{ field: 'name', order: 'ASC' }}
            >
            <AutocompleteInput
                    optionText={choice =>
                        `${choice?.name} `
                    }
                />
            </ReferenceInput>  
            <TextInput source="profile" label="Profile"  fullWidth={true} validate={required()}/>
            <RichTextInput source="notiDetails" label="Requisition Detail"    toolbar={[
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'link', 'video'],
          ['clean']
        ]}/>
            
            <NumberInput source="totalResource" label="No Of Resources" fullWidth={true} formClassName={classes.one_three_input} validate={required()} />
			      <ReferenceInput perPage={25}  filter={{ typeId: 1}} sort={{ field: 'firstName', order: 'ASC' }} filterToQuery={searchText => ({ 'firstName~like': searchText })} validate={[required()]} label="HR Lead" source="teamLeadId" reference="users" fullWidth={true} formClassName={classes.two_three_input}
                  
                    > 
              <AutocompleteInput
                    optionText={choice =>
                        `${choice?.firstName} ${choice?.lastName} `
                    }
                />
            </ReferenceInput>

            
			
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]} label="Work Shift" source="shiftId" reference="shifts" fullWidth={true} formClassName={classes.last_three_input}> 
              <AutocompleteInput
                    optionText={choice =>
                        `${choice?.name}`
                    }
                />
            </ReferenceInput>
                  
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]} label="Work Location" source="workLocationId" reference="work-locations" fullWidth={true} formClassName={classes.one_three_input}> 
              <AutocompleteInput
                    optionText={choice =>
                        `${choice?.name}`
                    }
                />
            </ReferenceInput>

            <NumberInput source="securePer" label="Min Secure %" fullWidth={true} formClassName={classes.two_three_input} validate={required()} />

          

            
            <BooleanInput source="isActive" label="Is Active" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
            
        </SimpleForm>
    </Create>);
}
