import React from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, DateInput,
    BooleanInput, NumberInput, ImageInput, ReferenceInput,SelectInput,AutocompleteInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {CreateToolbar} from "../../CreateToolbar";

export const SubjectPaymentCreate = props => (
    <Create {...props} title="Create Payment " redirect={false} successMessage="Subject Updated" undoable={false}>
        <SimpleForm redirect="list" toolbar={<CreateToolbar {...props}/>}
        variant="outlined">
                <TextInput   validate={required()} label="Payment Code"  source="name" fullWidth={true} />
                <ReferenceInput label="Payment Type" fullWidth={true}
                    perPage={5000} source="paymentTypeId" 
                    reference="statuses" 
                    filter={{ statusType: 'PAYMTYPE'}}
                    sort={{ field: 'status', order: 'ASC' }} 

                >
                    <SelectInput  optionText="status" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                {/* <ReferenceInput label="Subject" fullWidth={true}
                    perPage={5000} source="subjectId" 
                    reference="subjects" 
                    sort={{ field: 'name', order: 'ASC' }} 

                >
                    
                    <AutocompleteInput  variant="outlined" 
                optionText={choice => {
                    return (choice.name) ;
                }
                }
            />
                </ReferenceInput> */}
                <NumberInput   validate={required()} source="minRate" label={'Min Rate'}fullWidth={true} />
                <NumberInput   validate={required()} source="maxRate" label={'Max Rate'} fullWidth={true} />
                
                <BooleanInput initialValue={true}  source="isActive" />
            </SimpleForm>
    </Create>
);