import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";
export const CandidateLeaveCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true,
        fromDate : new Date()
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let fromDate = (formdata.fromDate) ? moment(formdata.fromDate).format() : null;
        let toDate = (formdata.toDate) ? moment(formdata.toDate).format() : null;
        //console.log("fromDate");
        //console.log(fromDate);
        let data = {
            candidateId: parseInt(props.employeeId),
            leaveTypeId: formdata.leaveTypeId,
            fromDate: fromDate,
            toDate: toDate,
            totalDays: formdata.totalDays,
            remarks: formdata.remarks,
            isActive: formdata.isActive
        }

        apiClient.createCandidateLeaves(data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <ReferenceInput label="Leave Type" validate={required()} source="leaveTypeId" reference="leave-types"
                    fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <DateInput source="fromDate" validate={required()} fullWidth={true} label="From Date" formClassName={classes.first_inline_input} />
                <DateInput source="toDate" validate={required()} fullWidth={true} label="To Date" formClassName={classes.last_inline_input} />

                <NumberInput validate={required()} fullWidth={true} source="totalDays" label="Total Days" />

                <TextInput fullWidth={true} multiline source="remarks" label="Remarks" />
                <BooleanInput source="isActive" label="Is Active" />
            </SimpleForm>
        </Edit>
    );
}
