import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer, useNotify
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import { CandidateAdd } from './CandidateAdd';
import { ActionAdd } from './ActionAdd';
import evaluator from '../evaluator';

const CandidateActionCreate = props => {  
    const classes = useStyles({});
    const notify = useNotify();
    const [initialValues, setSelectRecord] = useState({
        remarks: ""
    });  
    const handleSubmit = (formdata) => {
        console.log(formdata);
        let m = moment(new Date());
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
            userId = parseInt(userId);
        }
        let data = {
            notificationDetId: parseInt(props.record.id),
            statusId:parseInt(formdata.rstatusId),
            notificationStageId: parseInt(formdata.notificationStageId),
            typeId: parseInt(formdata.typeId),
            candidateId: parseInt(formdata.candidateId),
            testId: formdata.testId?parseInt(formdata.testId):0,
            evaluatorId: formdata.evaluatorId?parseInt(formdata.evaluatorId):0,
            remarks: formdata.remarks,
            isActive: true,
            createdBy: userId,
            modifyBy: 0,
            createdDate: m.toISOString(),
            modifyDate: m.toISOString(), 
        }
        apiClient.addEntiyRecord("notification-det-actions", data).then(res => {
            notify("notification-stages Created ")
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }


    return (
        <Create {...props}  >
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} showCancel={true} />}
                variant="outlined">
                <FormDataConsumer fullWidth={true} >
                    {formDataProps => (
                        <ActionAdd {...formDataProps} />
                    )}
                </FormDataConsumer>

            </SimpleForm>
        </Create>
    );
}
export default CandidateActionCreate;