import { AuthProvider } from 'ra-core';

const authProvider: AuthProvider = {
    login: ({ username }) => {
        localStorage.setItem('username', username);
        return Promise.resolve();
    },
    logout: () => {
        localStorage.removeItem('username');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
        getPermissions: (props) => {
            //debugger
            let per = localStorage.getItem("menu");
            if(per){
                per = JSON.parse(per);
            }
            return per ? Promise.resolve(per) : Promise.reject();
        },

};

export default authProvider;
