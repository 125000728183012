import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer, useNotify
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import { CandidateAdd } from './CandidateAdd';
import { ActionAdd } from './ActionAdd';
import Button from '@material-ui/core/Button';
const CandidateOnBoarding = props => {
    const classes = useStyles({});
    const notify = useNotify();
    const [initialValues, setSelectRecord] = useState({});
    const [empCode, setEmpCode] = useState(null);
    const [empEmail, setEmpEmail] = useState(null);
    const [empEmailPassword, setEmpEmailPassword] = useState(null);
    const [doj, setDoj] = useState(null);
    const [contractFile, setContractFile] = useState(null);
    const [typeId, setTypeId] = useState(0);
    const handleSubmit = (formdata) => {
        console.log(formdata);

        apiClient.CreateFreelancer(formdata).then(res => {
            //notify("notification-stages Created ")
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }


    const CreateFreelancer =async (isJoining) => {
        if (typeId == 46 && isJoining && (empCode == null || empCode == "")) {
            alert("Please enter FL Id");
            return

        }
        if (typeId == 46 && isJoining && (empEmail == null || empEmail == "")) {
            alert("Please enter email");
            return

        }
        if (typeId == 46 && isJoining && (empEmailPassword == null || empEmailPassword == "")) {
            alert("Please enter email password");
            return

        }
        if (typeId == 46 && isJoining && (empEmailPassword == null || empEmailPassword == "")) {
            alert("Please enter email password");
            return

        }
        if (typeId == 46 && doj && (doj == null || doj == "")) {
            alert("Please enter DOJ ");
            return

        }
        if (window.confirm("Are you sure you want to proceed")) {
            debugger
            let documentUrl="";
            if (contractFile) {
                if (contractFile != null) {
                    documentUrl = await apiClient.UploadFile("documentUrl", {rawFile:contractFile});
                    // if (response.files && response.files.length > 0) {
                    //     documentUrl = apiClient.getBaseUrl(response.files[0].url);
                    // }
                } else {
                    documentUrl = "";
                }
                
            }
            let data = {};
            data.empCode = empCode;
            data.emailId = empEmail;
            data.emailPassword = empEmailPassword;
            data.notificationDetId = props.record.id
            data.isJoined = isJoining;
            data.doj = doj;
            data.documentUrl=""; 
            apiClient.CreateFreelancer(data).then(res => {
                //notify("notification-stages Created ")
                props.onClose();
            }).catch(err => {
                console.log(err);
            });
        }


    }


    return (
        <Create {...props}  >
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={null}
                variant="outlined">


                <FormDataConsumer>
                    {({ formData }) => {
                        setTypeId(formData.typeId);
                        return null;
                    }}
                </FormDataConsumer>
                {typeId == 46 && <TextInput
                    onChange={(e) => setEmpCode(e.target.value)}
                    source="empCode"
                    variant="outlined"
                    label="Enter FL Id"
                    fullWidth={true}
                />}
                {typeId == 46 && <TextInput
                    onChange={(e) => setEmpEmail(e.target.value)}
                    source="emailId"
                    variant="outlined"
                    label="Enter Email"
                    fullWidth={true}
                />}
                {typeId == 46 && <TextInput
                    onChange={(e) => setEmpEmailPassword(e.target.value)}
                    source="emailPassword"
                    variant="outlined"
                    label="Enter Email Password"
                    fullWidth={true}
                />}

{typeId == 46 && <DateInput
                    onChange={(e) => setDoj(e.target.value)}
                    source="doj"
                    variant="outlined"
                    label="DOJ"
                    fullWidth={true}
                />}

                {/* {<FileInput  source="documentUrl" formClassName={classes.first_inline_input}  onChange={(e)=>setContractFile(e)}
                            label="Contract " className="logourl">
                            <FileField source="documentUrl" title="Contract"  />
                        </FileInput>
                        
                        } */}
                        

                <div style={{ display: 'flex', flexDirection: 'row' }} >

                    {

                        <Button style={{ marginLeft: 10, fontSize: 9, backgroundColor: '#02384d', color: 'white' }}
                            color="primary"
                            size="medium"
                            onClick={() => { CreateFreelancer(true) }}
                        >{"Confirm FL"}</Button>
                    }
                    {
                        <Button style={{ marginLeft: 10, fontSize: 9, backgroundColor: 'red', color: 'white' }}
                            color="primary"
                            size="medium"
                            onClick={() => { CreateFreelancer(false) }}
                        >{"Not Joining"}</Button>
                    }
                    {
                        <Button style={{ marginLeft: 10, fontSize: 9, backgroundColor: '#02384d', color: 'white' }}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                props.onCancel()
                            }}
                        >{"Back"}</Button>
                    }
                </div>


            </SimpleForm>
        </Create>
    );
}
export default CandidateOnBoarding;