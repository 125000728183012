import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { lightTheme } from '../layout/themes';
import { Location } from 'history';
import logo from "../images/logo.png";
import * as apiClient from "../apiClient";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        width: "80%",
        maxWidth: "100%",
        marginTop: '2em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));
interface IMeta {
    touched: boolean;
    error: Error;
}

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
        <TextField variant="outlined"
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
        />
    );

interface FormValues {
    firstname?: string;
    lastname?: string;
    email?: string;
    mobile?: string;
    username?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const EditProfile = ({ location }: { location: Location }) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles({});
    const notify = useNotify();
    const login = useLogin();
    let history = useHistory();

    const firstname = localStorage.getItem("firstname");
    const lastname = localStorage.getItem("lastname");
    const mobile = localStorage.getItem("mobile");
    const email = localStorage.getItem('username');
    //localStorage.setItem("userId", user.userId);

    const handleSubmit = (auth: FormValues) => {
        localStorage.setItem("firstname", auth.firstname!);
        localStorage.setItem("lastname", auth.lastname!);
        localStorage.setItem("email", auth.email!);
        localStorage.setItem('mobile', auth.mobile!);
        notify("Profile updated successfully", 'info');
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.firstname) {
            errors.firstname = translate('ra.validation.required');
        }
        if (!values.lastname) {
            errors.lastname = translate('ra.validation.required');
        }
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.mobile) {
            errors.mobile = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <HowToRegIcon />
                                </Avatar>
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                     defaultValue={firstname}
                                        initialValue={firstname}
                                        autoFocus
                                        name="firstname"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="First Name"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        initialValue={lastname}
                                        autoFocus
                                        name="lastname"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="Last Name"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        initialValue={email}
                                        autoFocus
                                        name="email"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="Email Address"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        initialValue={mobile}
                                        name="mobile"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="Mobile"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    Update profile
                                </Button>
                            </CardActions>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

EditProfile.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles({}) hook used in Register won't get
// the right theme
const EditProfileWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <EditProfile {...props} />
    </ThemeProvider>
);

export default EditProfileWithTheme;
