import React, { useState, useEffect } from 'react';
import { useForm } from 'react-final-form';
import { Editor } from '@tinymce/tinymce-react';

export const ContactInfo = ({ formData, ...rest }) => {
    const form = useForm();
    const [editorValue, setEditorValue] = useState(formData.projectContactInformation);

    useEffect(() => {
        setEditorValue(formData.projectContactInformation);
    }, [formData.projectContactInformation]);

    const handleEditorChange = (content) => {
        setEditorValue(content);
        form.change('projectContactInformation', content);
    };

    return (
        <div>
            <h6>Contact Information</h6>
            <Editor
              apiKey="gsr9de585py37ujyollv3160go05b3xmbqo7r54eq3l4iise"
                value={editorValue}
                disabled
                init={{
                    height: 500,
                    language: 'en',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic backcolor | ' +
                             'alignleft aligncenter alignright alignjustify | ' +
                             'bullist numlist outdent indent | removeformat | help | table'
                }}
                onEditorChange={handleEditorChange}
            />
        </div>
    );
};
