
import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";
import { withRouter } from 'react-router-dom';
import { Link } from 'ra-ui-materialui';
const styles = {
    button: {
        marginTop: "1em",
    },
};

const AddNewQuestionButton = withStyles(styles)(({ classes, record }) => (
    <Button
        className={classes.button}
        variant="raised"
        component={Link}
        to={{
            pathname: "/testquestions/create",
            search: `?test_id=${record.id}`,
        }}
        label="Add New Question">
        <ImageIcon />
    </Button>
));

export default withRouter(AddNewQuestionButton);