import React,{useState} from 'react';
import {
    required,     minLength,    maxLength,    minValue,    maxValue,
    number,    regex,    email,    choices,    Create, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput,
    BooleanInput, NumberInput
} from 'react-admin';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import { useStyles } from "../../formStyles";
import * as apiClient from "../../apiClient";
export const UserCreate = props => {
    const classes = useStyles({}); 
    const history = useHistory();    
    const [initialValues, setSelectRecord] = useState({
        typeId:1
    }); 

    const handleSubmit = (formdata) => { 
        apiClient.addEntiyRecord('freelancer-users',formdata).then(res => {
            history.push(`/freelancer-users`);
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Create {...props} redirect="list" title={"Freelancer Create"}>
            <SimpleForm 
            
            onSubmit={handleSubmit}
            initialValues={initialValues}
            defaultValue={{ middleName: "", pwd: "", roleId: 3, middleName: "", lastName: "",address:"",pinCode:"",status:true,typeId: 1 }}
            variant="outlined">
                <TextInput source="userName" validate={[required(), ]} fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="initials" fullWidth={true} formClassName={classes.last_inline_input} />
                <TextInput source="firstName" fullWidth={true} validate={required()}  formClassName={classes.one_three_input} />
                <TextInput source="middleName" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="lastName" fullWidth={true} validate={required()}  formClassName={classes.last_three_input} />
                <TextInput source="email" fullWidth={true} validate={[required(), email()]} formClassName={classes.first_inline_input} />
                <TextInput source="mobile" fullWidth={true} validate={required()} formClassName={classes.last_inline_input} />
                <TextInput source="address" fullWidth={true} />
                <TextInput source="pinCode" fullWidth={true} formClassName={classes.first_inline_input} />
                <ReferenceInput label="User Role" source="roleId" reference="role-types" 
                fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="roleName" />
                </ReferenceInput>
                <TextInput source="pwd" fullWidth={true} validate={[required(), minLength(8), maxLength(15)]} />
                <BooleanInput source="status" />
            </SimpleForm>
        </Create>
    );
}