import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,AutocompleteInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const WorkRolesCreate = props => (
    <Create {...props} title="Work Roles" redirect={false} successMessage="Work Role Updated" undoable={false}>
        <SimpleForm redirect="list" toolbar={<CreateToolbar {...props} />} variant="outlined">
            <TextInput source="name" label="Name" />
            <TextInput source="description" label="Description" />
            <ReferenceInput source="departmentId" reference="departments" perPage={5000}
            filterToQuery={searchText => ({ 'name~like': searchText })}
            sort={{ field: 'name', order: 'ASC' }} 
            >
            <AutocompleteInput  variant="outlined" 
                optionText={choice => {
                    return (choice?.name) ;
                }
                }
            />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
