import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField
}
    from 'react-admin';
import moment from "moment";

const MyDateField = ({ source, record = {} }) => {
    let data = record[source];
    console.log(data);
    if(data){
        data = moment(data).format("DD-MM-YYYY"); 
    }
    return (
        <span>{data}</span>
        );
    }


export const HolidaysList  = props => {
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
    return (
        <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}  >
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
            <MyDateField source="date" label="Date" />
            <TextField source="name" label="Name" />
            <TextField source="description" label="Description" />
            <ReferenceField source="createdBy" reference="employees" label="Created By" link={false}>
                <TextField source="firstName" />
            </ReferenceField>
            <DateField source="createdDate" label="Created Date" />
            <ReferenceField source="modifyBy" reference="employees" label="Modified By" link={false}>
                <TextField source="firstName" />
            </ReferenceField>
            <DateField source="modifyDate" label="Modified Date" />
        </Datagrid>
    </List>
)};