import React from 'react';
import { List, Datagrid, TextField, DateField, NumberField, BooleanField ,EditButton, ReferenceField,Filter,TextInput} from 'react-admin';

export const SubjectPaymentList = props => {
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
    const Filters = props => (
        <Filter {...props} variant="outlined">
            <TextInput variant="outlined" label="Payment Code" source="name~like" alwaysOn />  
        </Filter>
    );
   return( <List filters={<Filters />} {...propsObj} exporter={propsObj.hasExport} title="Payments" bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} >
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
                {propsObj.hasEdit && <EditButton />}
    
            <TextField source="name" label="Payment Code" />
{/*             
            <ReferenceField source="subjectId" reference="subjects" label="Subject "  link={false}>
                <TextField source="name" />
            </ReferenceField> */}
            
            <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                                    <TextField source="userName" />
                                    </ReferenceField>
                                    <DateField  source="createdDate" label="Created Date"/>
                                    <ReferenceField source="modifyBy" reference="employees" label="Modified By"  link={false}>
                                        <TextField source="firstName" />
                                    </ReferenceField>
                                    <DateField  source="modifyDate" label="Modified Date"/>
            
        </Datagrid>
    </List>
)};