import { Button, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import * as apiClient from "../../apiClient";
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField, useNotify,

} from 'react-admin';
import { useStyles } from "../../formStyles";
import { MultipleSelect } from "react-select-material-ui";

export const EmployeeFields = ({ formData, ...rest }) => {
    const notify = useNotify();
    const [empId, setEmpId] = useState("")
    const [name, setName] = useState("")
    const [emailId, setEmailId] = useState("")
    const [triviumemailId, setTriviumemailId] = useState("")
    const [employmentType, setEmploymentType] = useState(46)
    const [cols, setCols] = useState([]);
    const [griddata, setData] = useState([]);
    const [fetching, setFetching] = useState(false);  
    const [verticalId, setVerticalId] = useState(0)
    const [businessId, setBusinessId] = useState(0)

    const [tags, setTags] = useState([])
    const [options, setOptions] = useState([])
    const [subjects, setSubjects] = useState([])
    const [selectedsubjects, setSelectedSubjects] = useState([])

    const [keyskilltags, setKeySkillsTags] = useState([])
    const [keyskilloptions, setKeySkillOptions] = useState([])
    const [keyskills, setKeySkills] = useState([])
    const [selectedkeyskills, setSelectedKeySkills] = useState([])



    const [experiencetags, setExperienceTags] = useState([])
    const [experienceoptions, setExperienceOptions] = useState([])
    const [experiences, setExperiences] = useState([])
    const [selectedExperiences, setSelectedExperiences] = useState([])

    const handleChange = async (value) => {

        let data = [...tags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedSubjects(data[data.length - 1])
        }
        if (value == null) {
            setSelectedSubjects([])
        }
        setTags([...data])
    };

    const handleKeySkillChange = async (value) => {
        
        let data = [...keyskilltags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedKeySkills(data[data.length - 1])
        }
        if (value == null) {
            setSelectedKeySkills([])
        }
        setKeySkillsTags([...data])
    };

    const handleExperienceChange = async (value) => {
        
        let data = [...keyskilltags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedExperiences(data[data.length - 1])
        }
        if (value == null) {
            setSelectedExperiences([])
        }
        setExperienceTags([...data])
    };


    

    useEffect(() => {
        loadSubjects()
        loadKeyskills();
        loadExperinces();
    }, []);
    const loadSubjects = async () => {
        apiClient.GetNotificationSubjects(rest.notificationId).then((res) => {
            let data = [];
            let dataoption = [];
            setSubjects(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setOptions(dataoption);
        }
        )
    } 
    const handleSelectAll = async (e) => {
        let data = [...griddata];
        for (let index = 0; index < data.length; index++) {
            data[index].selected = e.target.checked;
        }
        setData(data);
    }
    const handleSelect = async (e, rowIndex) => {
        let data = [...griddata];
        data[rowIndex].selected = e.target.checked;
        setData(data);
    }
    const handleSubmit = async () => { 
        let data = [...griddata];
        let selectedData = []
        selectedData = data.filter(x => x.selected === true);
        if (selectedData === undefined || selectedData.length === 0) {
            alert("Please select Resource to save");
            return;
        }
        let UserId = localStorage.getItem("userId");
        
        if (UserId) {
            UserId=parseInt(UserId);
            
        }
        let formData = [];
        for (let index = 0; index < selectedData.length; index++) {
            formData.push({ id: 0, NotificationID: rest.notificationId, EmployeeID: selectedData[index].EmployeeID, TypeId: selectedData[index].TypeId,UserId:UserId })
        }
        apiClient.addResource({ data: formData }).then((res) => {
            if (res[0].result === true) {
                notify(res[0].message);
                rest.onClose();
            }
            else { alert(res[0].message); }
        }
        )

    }
    const searchResource = async () => {
        let _keyskills = [...selectedkeyskills]
        let _subjects = [...selectedsubjects]
        let _experinces = [...selectedExperiences]
        let subjectstr = "";
        let keyskillsstr = "";
        let experincesstr = "";

        for (let index = 0; index < _subjects.length; index++) {
            let s = _subjects[index];
            let subjectItem = subjects.filter(x => x.name === s);
            if (subjectItem != undefined && subjectItem != null && subjectItem.length > 0) {
                if (subjectstr.length === 0) {
                    subjectstr = subjectItem[0].id;
                }
                else {
                    subjectstr = `${subjectstr},${subjectItem[0].id}`
                }
            }
        }
        for (let index = 0; index < _keyskills.length; index++) {
            let s = _keyskills[index];
            let keyskillsItem = _keyskills.filter(x => x.name === s);
            if (keyskillsItem != undefined && keyskillsItem != null && keyskillsItem.length > 0) {
                if (keyskillsstr.length === 0) {
                    keyskillsstr = keyskillsItem[0].id;
                }
                else {
                    keyskillsstr = `${keyskillsstr},${keyskillsItem[0].id}`
                }
            }
        }
        for (let index = 0; index < _experinces.length; index++) {
            let s = _experinces[index];
            let experinceItem = _experinces.filter(x => x.name === s);
            if (experinceItem != undefined && experinceItem != null && experinceItem.length > 0) {
                if (experincesstr.length === 0) {
                    experincesstr = experinceItem[0].id;
                }
                else {
                    experincesstr = `${experincesstr},${experinceItem[0].id}`
                }
            }
        }
        let UserId = localStorage.getItem("userId");
        
        if (UserId) {
            UserId=parseInt(UserId);
            
        }
       
        let formData = {
            NotificationID: rest.notificationId,
            EmpCode: empId,
            EmpName: name,
            Email: emailId,
            MobileNo: '',
            KeySkillId: keyskillsstr,
            ExperinceId:experincesstr,
            WorkRoleID: verticalId,
            WorkRoleProjectID: businessId,
            SubjectID: subjectstr,
            ROEmail: triviumemailId,
            EmployeRoleTypeID: employmentType,
            UserId:UserId
        }
        setFetching(true);
        apiClient.searchResource(formData).then((res) => {
            setData(res);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                let data = [];
                for (let index = 0; index < keys.length; index++) {
                    if (keys[index] != "EmployeeID" && keys[index] != "TypeId") {
                        data.push(keys[index])
                    }
                }
                setCols(data);
            }
            setFetching(false);
        }
        )
    }
    const loadKeyskills = async () => {
        apiClient.getKeySkills().then((res) => {
            let data = [];
            let dataoption = [];
            setKeySkills(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setKeySkillOptions(dataoption);

        }
        )
    }
    const loadExperinces = async () => {
        apiClient.getExperiences().then((res) => {
            let data = [];
            let dataoption = [];
            setExperiences(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setExperienceOptions(dataoption);

        }
        )
    }
    
    const classes = useStyles({});
    return (
        <SimpleForm variant="outlined" toolbar={null}  >
            <div><span style={{fontSize:"22"}}>Search Freelancer/Candidate</span></div>
            <TextInput source="empID" label="Freelancer/Candidate ID " fullWidth={true} variant="outlined" formClassName={classes.one_4_input} onChange={(e) => { setEmpId(e.target.value); }} />
            <TextInput source="name" label="Name " fullWidth={true} variant="outlined" formClassName={classes.two_4_input} onChange={(e) => { setName(e.target.value); }} />
            <TextInput source="emailId" label="EmailId" fullWidth={true} variant="outlined" formClassName={classes.three_4_input} onChange={(e) => { setEmailId(e.target.value); }} />
            <TextInput source="triviumemailId" label="Mobile No " fullWidth={true} variant="outlined" formClassName={classes.last_4_input} onChange={(e) => { setTriviumemailId(e.target.value); }} /> 
            <ReferenceInput validate={required()}filter={{ statusType: 'EMPTYPE'}}
                    sort={{ field: 'status', order: 'ASC' }}  label="Type" source="typeId" reference="statuses" variant="outlined" fullWidth={true} formClassName={classes.one_4_input} onChange={(e)=>{setEmploymentType(e.target.value);}}
                    initialValue={46}
                     >
                <SelectInput optionText="status" fullWidth={true} formClassName={classes.two_4_input}   /> 
            </ReferenceInput> 
            <ReferenceInput validate={required()} label="Vertical" source="workroleId" reference="work-roles" variant="outlined" fullWidth={true} formClassName={classes.two_4_input} onChange={(event)=>{setVerticalId(event.target.value)}} >
                <SelectInput optionText="name" fullWidth={true} formClassName={classes.two_4_input}   /> 
            </ReferenceInput>
            <ReferenceInput validate={required()} label="Business" source="businessId" reference="work-role-projects" variant="outlined" fullWidth={true} formClassName={classes.three_4_input} onChange={(event)=>{setBusinessId(event.target.value)}}  >
                <SelectInput optionText="name" fullWidth={true} formClassName={classes.three_4_input} />
            </ReferenceInput>  
            <div style={{ width: "33%", float: "left" }}>
            {/* <ReferenceInput validate={required()} label="Business" source="businessId" reference="work-role-projects" variant="outlined" fullWidth={true} formClassName={classes.three_4_input} onChange={(event)=>{setBusinessId(event.target.value)}}  >
                <SelectInput optionText="name" fullWidth={true} formClassName={classes.three_4_input} />
            </ReferenceInput>   */}
            </div>
            <div style={{ width: "33%", float: "left" }}>
                <MultipleSelect label="Choose Subject" values={tags} options={options}
                    helperText="You can search multiple Subject" onChange={handleChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All Subjects are selected", msgNoOptionsMatchFilter: "No Subject name matches the filter", }} />
            </div>
            {/* <div style={{ width: "33%", float: "left" }}>
                <MultipleSelect label="Choose Technical Skills" values={keyskilltags} options={keyskilloptions}
                    helperText="You can search multiple Technical Skills" onChange={handleKeySkillChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All Technical Skill are selected", msgNoOptionsMatchFilter: "No Technical Skill name matches the filter", }} />
            </div> */}
            <div style={{ width: "33%", float: "left" }}>
                <MultipleSelect label="Choose Experience" values={experiencetags} options={experienceoptions}
                    helperText="You can search multiple experiences " onChange={handleExperienceChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All experiences are selected", msgNoOptionsMatchFilter: "No Experience matches the filter", }} />
            </div>
            <div style={{ width: "34%", float: "left", marginTop: "13px", display: "flex" }}>
                <div style={{ marginRight: "10px" }} ><Button variant="contained" color="primary" style={{ leftMargin: "10px" }} onClick={searchResource}  >Search</Button></div>
                <div style={{ marginRight: "10px" }}><Button variant="contained" color="primary" style={{ leftMargin: "10px" }} onClick={handleSubmit} >Save</Button></div>
                <div style={{ marginRight: "10px" }}><Button variant="contained" color="primary" style={{ leftMargin: "10px" }} onClick={() => rest.onCancel()} >Back</Button></div>

            </div>
            {fetching && <div ><CircularProgress /></div>}
            {griddata && griddata.length > 0 && <div >
                <table   className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny font-size-12">
                    <tr>
                        <th key={0}><input type="checkbox" onChange={(e) => handleSelectAll(e)} /> </th>
                        {cols && cols.map((item, index) => {
                            return (
                                <th key={item}>{item}</th>
                            )
                        })}
                    </tr>

                    {griddata && griddata.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex}>

                                <td key={0}><input type="checkbox" checked={row.selected != undefined && row.selected != null ? row.selected : false} onChange={(e) => handleSelect(e, rowIndex)} /> </td>
                                {cols && cols.map((col, colIndex) => {
                                    return (
                                        <td key={rowIndex + "-" + colIndex}>
                                            {row[col]}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </table>
            </div>
            }

        </SimpleForm>

    )
}
